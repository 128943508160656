@import "./mediaquery";
@import "./variables";

.reportytemplate {
  height: fit-content !important;

  .payslip {
    background-color: white;
    width: 100%;
    margin: auto;
    border-radius: 8px;

    .top {
      background-color: $primary;
      color: white;
      display: grid;
      grid-template-columns: 1fr auto auto;
      gap: 1em;
      padding: 4px 10px;
      border-radius: 5px 5px 0 0;

      h2 {
        font-size: medium;
        margin: auto;
        display: block;
        width: 100%;
        font-weight: 520;
      }

      button {
        background-color: transparent;
        border: 1px solid white;
        color: white;
        cursor: pointer;
        border-radius: 5px;
        padding: 5px 1em;
        transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out,
          border-radius 0.5s ease-in-out;
      }
      button:hover {
        background-color: white;
        color: $primary;
        border-radius: 0;
      }
    }

    .divsplit {
      display: grid;
      grid-template-columns: 2fr 1fr;
      gap: 1em;
      background-color: white;

      .iright_panel {
        padding: 1em;

        .links {
          background-color: white;
          border-radius: 5px;
          padding: 1em;
          border: 1px solid #60606010;
          box-shadow: 1px 1px 5px #60606010;

          .ic {
            position: absolute;
            top: 0;
            bottom: 0;
            height: 100%;
            left: 10px;
            margin-right: 1em;
            display: grid;
            place-content: center;
          }

          button {
            padding: 8px 1em;
            border: 1px solid $primary;
            border-radius: 5px;
            cursor: pointer;
            background-color: $primary;
            transition: background-color 0.5s ease-in-out,
              color 0.5s ease-in-out;
            width: 100%;
            color: white;
            margin: 10px 0;
            position: relative;
          }
          button:first-child {
            background-color: $secondary;
            border: 1px solid $secondary;
          }
          button:last-child {
            background-color: $primarylight;
            border: 1px solid $primarylight;
          }
          button:hover {
            background-color: transparent;
            color: $primary;
          }
        }

        .c_payments {
          background-color: white;
          border-radius: 5px;
          padding: 1em;
          border: 1px solid #60606010;
          box-shadow: 1px 1px 5px #60606010;
          margin: 1em 0;
          height: fit-content;

          h3 {
            font-size: medium;
            font-weight: 520;
            color: $primary;
          }

          hr {
            border: 1px solid #60606030;
            margin: 8px 0;
          }

          .pitem {
            display: grid;
            grid-template-columns: auto 1fr auto;
            gap: 10px;
            background-color: white;
            border-radius: 5px;
            padding: 10px;
            border: 1px solid #60606010;
            box-shadow: 1px 1px 5px #60606010;
            margin-bottom: 10px;
            position: relative;
            cursor: pointer;
            transition: background-color 0.5s ease-in-out;

            .fa-times {
              position: absolute;
              top: 2px;
              right: 2px;
              color: $accent;
              cursor: pointer;
              transition: color 0.5s ease-in-out;
            }

            .fa-times:hover {
              color: red;
            }

            .no {
              height: 32px;
              width: fit-content;
              padding: 10px 1em;
              border-radius: 32px;
              background-color: antiquewhite;
              border: 1px solid #60606010;
              display: grid;
              place-content: center;
            }

            h4 {
              font-size: smaller;
              font-weight: 500;
            }

            p {
              font-size: x-small;
            }

            h5 {
              display: block;
              margin: auto;
              padding: 4px 10px;
              border-radius: 24px;
              background-color: rgba(0, 128, 0, 0.2);
              color: green;
              font-weight: 400;
              font-size: smaller;
            }
          }
          .pitem:hover {
            background-color: wheat;
          }
        }
      }
    }

    .inv_preview {
      background-color: white;
      position: relative;
      padding: 1em;
      max-width: 650px;
      width: 100%;
      overflow-x: auto;
      overflow-y: hidden;
      margin: auto;

      .invoice {
        max-width: 595px;
        background-color: white;
        width: 100%;
        height: 100%;
        margin: auto;
        border-radius: 5px;
        box-shadow: 2px 2px 10px #60606050;
        padding-bottom: 74px;

        .bar {
          display: grid;
          grid-template-columns: 1fr auto 24px;
          place-items: center;
          gap: 1em;

          .invtop {
            padding: 1em 2em;
            width: 100%;

            img {
              max-width: 200px;
              width: 100%;
              object-fit: contain;
            }

            h2 {
              font-weight: 600;
              margin-top: 10px;
              font-size: medium;
            }

            p {
              font-size: small;
            }
          }

          .photo {
            width: 150px;
            height: 80%;
            object-fit: contain;
            border: 2px solid $primary;
            border-radius: 5px;
          }

          .div {
            height: 80%;
            width: 100%;
            background-image: linear-gradient(
              $primary 0%,
              $primarylight 30%,
              $primarylighter 50%,
              $primarylight 70%,
              $primary 100%
            );
          }

          h1 {
            font-size: 44px;
            height: 44px;
            line-height: 44px;
            color: $primary;
          }
        }

        .invheader {
          display: grid;
          grid-template-columns: 2fr 1fr;
          gap: 2em;
          padding: 0 2em 0 2em;

          .left {
            .enclose {
              border: 1px solid #60606050;
              border-radius: 5px;
              height: 100%;
              width: fit-content;
              display: grid;
              grid-template-rows: repeat(2, 1fr);
              text-align: center;

              h4 {
                background-color: $primary;
                padding: 2px;
                font-size: small;
                color: white;
                display: grid;
                place-content: center;
              }
              p {
                color: $primary;
                font-size: medium;
                display: grid;
                place-content: center;
              }
            }

            .div1auto {
              display: grid;
              grid-template-columns: 1fr auto;
              width: fit-content;
              gap: 10px;
              margin-bottom: 5px;
            }

            p {
              font-size: smaller;
            }

            h4 {
              font-weight: 510;
              font-size: smaller;
            }
          }

          .right {
            width: 100%;
            height: fit-content;
            margin: auto;

            .div1auto {
              display: grid;
              grid-template-columns: 1fr auto;
              margin-bottom: 5px;
            }

            h4 {
              font-weight: 520;
              color: $primary;
            }

            p {
              font-size: medium;
            }
          }
        }

        .section {
          border-radius: 5px;
          margin: 1em 2em 1em 2em;
          min-height: 250px;
          border: 0.5px solid #60606030;
          border-collapse: collapse;

          .div3 {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
          }

          .td {
            border: 0.5px solid #60606030;
            border-collapse: collapse;
            padding: 2px;
            text-align: left;
            width: 100%;
          }

          .sect {
            width: 100%;
            .div21 {
              width: 100%;
              display: grid;
              grid-template-columns: 1fr 3fr;
            }

            th {
              font-size: smaller;
              font-weight: 520;
            }

            h5 {
              font-size: smaller;
              font-weight: 520;
            }

            h4 {
              background-color: $primary;
              color: white;
              padding: 2px;
              text-align: center;
              font-weight: 520;
              font-size: smaller;
            }
            p {
              font-size: small;
              color: #686868;
              display: block;
              margin: auto;
            }

            .div1a {
              display: grid;
              grid-template-columns: auto 1fr;
            }

            input {
              border: none;
              outline: none;
              padding: 2px;
              font-size: smaller;
              text-transform: capitalize;
              font-weight: 400;
              max-width: 160px;
              width: 100% !important;
            }
          }
        }

        .close {
          padding: 0 2em;

          h6 {
            font-weight: 520;
            font-style: italic;
          }

          .mark {
            margin: 1em 0;
            display: grid;
            grid-template-columns: 1fr 2fr;

            h5 {
              border: 1px solid #60606030;
              font-size: smaller;
              padding: 4px;
              font-weight: 500;
            }
            .result {
              background-color: green;
              color: white;
              font-size: large;
              display: grid;
              place-content: center;
              padding: 4px;
            }
          }

          .key {
            margin: 10px 0;
            width: fit-content;
            h5 {
              font-size: smaller;
              padding: 4px;
              font-weight: 500;
            }

            .div12 {
              display: grid;
              grid-template-columns: 24px 1fr;

              div {
                height: 16px;
                width: 24px;
                border-width: 1px;
                border-style: solid;
              }

              p {
                height: 100%;
                width: 100%;
                font-size: small;
                padding: 0 1em;
                border: 1px solid #60606030;
              }
            }
          }
          h2 {
            margin: 10px 0;
            font-size: smaller;
            padding: 4px;
            font-weight: 500;
          }
        }
      }
      .invfooter {
        padding: 1em 0;
        margin-top: -70px;

        h5 {
          text-align: center;
          font-size: medium;
          font-weight: 560;
          color: $primary;
        }
      }

      .watermark {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: #60606030;
      }
    }
  }

  .popup {
    .wrap {
      background-color: white;
      max-width: 60vw;
      width: 100%;
      margin: auto;
      border-radius: 8px;

      .bar {
        background-color: $primary;
        display: grid;
        grid-template-columns: 1fr auto;
        padding: 10px;
        h4 {
          color: white;
          font-weight: 500;
        }
      }
      form {
        padding: 1em;

        h6 {
          color: $accent;
          text-align: center;
          margin: 10px 0;
          font-weight: 500;
          font-size: medium;
        }

        h4 {
          font-weight: 500;
          margin-bottom: 1em;
        }

        .checkbox {
          display: grid;
          grid-template-columns: auto 1fr;
          gap: 10px;
          margin: 8px 0;

          input {
            border-radius: 5px;
          }
        }

        button {
          padding: 8px 1em;
          background-color: $primary;
          color: white;
          border: 1px solid $primary;
          cursor: pointer;
          border-radius: 5px;
          display: block;
          margin: auto;
          min-width: 200px;
          box-shadow: 1px 1px 5px #60606010;
          transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out;
        }
        button:hover {
          color: $primary;
          background-color: transparent;
        }
      }
    }

    .mrap {
      background-color: white;
      max-width: 40vw;
      width: fit-content;
      margin: auto;
      border-radius: 8px;

      h6 {
        color: $accent;
        text-align: center;
        margin: 10px 0;
        font-weight: 500;
        font-size: medium;
      }

      button {
        padding: 8px 1em;
        background-color: $primary;
        color: white;
        border: 1px solid $primary;
        cursor: pointer;
        border-radius: 5px;
        display: block;
        margin: auto;
        min-width: 200px;
        box-shadow: 1px 1px 5px #60606010;
        transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out;
      }
      button:hover {
        color: $primary;
        background-color: transparent;
      }

      .bar {
        background-color: $primary;
        display: grid;
        grid-template-columns: 1fr auto;
        padding: 10px;
        h4 {
          color: white;
          font-weight: 500;
        }
      }
      .section {
        padding: 1em;
        .div2equal {
          margin: 0 !important;
          gap: 0 !important;
          grid-template-columns: 2fr 1fr !important;

          h3 {
            background-color: antiquewhite;
            color: $primary;
            font-size: smaller;
            font-weight: 520;
            border: 1px solid #60606010;
            height: 100%;
            padding: 4px;
          }

          h4 {
            background-color: rgb(252, 246, 239);
            font-size: smaller;
            font-weight: 500;
            border: 1px solid #60606010;
            height: 100%;
            padding: 4px;
          }

          p {
            background-color: white;
            font-size: smaller;
            font-weight: 500;
            border: 1px solid #60606010;
            height: 100%;
            padding: 4px;
            width: 100%;
            text-align: right;
            display: grid;
            align-items: center;
            // place-content: center;
          }

          input {
            background-color: white;
            font-weight: 500;
            border: 1px solid #60606010;
            padding: 4px;
            height: 100%;
            width: 100%;
            text-align: right;
            display: grid;
            place-content: center;
            outline: none;
          }
        }
      }
    }
  }
}
