@import "./mediaquery";
@import "./variables";

.folders {

  h6 {
    font-size: small;
    font-weight: 400;
    text-align: center;
    height: 16px;
    line-height: 16px;
    margin: 5px 0 5px 0;
    color: $secondary;
  }

  .wrap {
    background-color: white;
    border-radius: 5px;

    .top {
      background-color: $primary;
      padding: 5px;
      display: grid;
      grid-template-columns: auto auto 1fr;
      gap: 1em;
      border-radius: 5px 5px 0 0;

      p {
        color: white;
        font-size: small;
        cursor: pointer;
        padding: 5px 10px;
        border: 1px solid $primary;
        border-radius: 5px;
      }
      p:hover {
        border: 1px solid white;
      }

      .search {
        margin-left: auto;
        position: relative;

        .fa-search {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 10px;
          height: 100%;
          display: flex;
          align-items: center;
        }

        input {
          padding: 5px 10px;
          background-color: white;
          color: $secondary;
          outline: none;
          border: none;
          border-radius: 5px;
        }
      }
    }

    .list {
      padding: 1em;
      min-height: 80vh;

      .path {
        display: flex;
        gap: 5px;
        position: relative;

        .fa-refresh {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 10px;
          display: flex;
          align-items: center;
          color: $primary;
          cursor: pointer;
        }
        .fa-refresh:hover {
          color: $primary;
        }

        p {
          background-color: $bg1;
          cursor: pointer;
          padding: 5px 10px;
          border-radius: 44px;
          font-size: small;
        }
        p:hover {
          background-color: $primary;
          color: white;
        }
      }

      h3 {
        color: $primary;
        font-size: small;
        font-weight: 500;
      }

      hr {
        color: #60606010;
        margin: 5px 0 10px 0;
      }

      .fld {
        border-radius: 5px;
        border: 1px solid #60606010;
        box-shadow: 1px 1px 5px #60606010;
        display: grid;
        grid-template-columns: auto 1fr auto;
        gap: 10px;
        cursor: pointer;
        margin-bottom: 10px;

        p {
          font-size: small;
          display: flex;
          align-items: center;
          width: 100%;
          padding: 5px;
        }

        .fd-icon {
          display: flex;
          align-items: center;
          color: #f1d592;
          margin: auto 0 auto 10px;
        }

        .fa-times {
          display: flex;
          align-items: center;
          color: $secondary;
          cursor: pointer;
          margin: auto 10px auto 0;
        }
        .fa-times:hover {
          color: $primary;
        }
      }
      .fl {
        border-radius: 5px;
        display: grid;
        grid-template-columns: auto 1fr auto auto;
        gap: 10px;
        cursor: pointer;
        margin: 5px 0 5px 0;
        background-color: $bg1;

        p {
          font-size: small;
          display: flex;
          align-items: center;
          width: 100%;
          padding: 3px;
        }

        .fa-download {
          display: flex;
          align-items: center;
          color: $primary;
          cursor: pointer;
          margin: auto;
        }
        .fd-icon {
          display: flex;
          align-items: center;
          color: $primary;
          margin: auto 0 auto 10px;
        }
        .fa-times {
          display: flex;
          align-items: center;
          color: $secondary;
          cursor: pointer;
          margin: auto 10px auto 0;
        }
        .fa-times:hover {
          color: $primary;
        }
      }
    }

    @include maxwidth(mobile) {
      .top {
        grid-template-columns: 1fr 1fr;

        .search {
          display: none;
        }
      }
    }
    @include maxwidth(tablet) {
      .top {
        grid-template-columns: 1fr 1fr;

        .search {
          display: none;
        }
      }
    }
  }

  .popup {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    z-index: 999;
    background-color: rgba($color: #000000, $alpha: 0.1);
    padding: 2em;

    .pwrap {
      max-width: 400px;
      width: 100%;
      margin: auto;
      padding: 1em;
      background-color: white;
      border-radius: 5px;
      border: 1px solid #60606010;
      box-shadow: 1px 1px 5px #60606030;

      .button {
        background-color: $primary;
        border: none;
        border-radius: 5px;
        padding: 10px 2em;
        margin: auto;
        display: block;
        cursor: pointer;
        color: white;
        box-shadow: 1px 1px #60606030;
      }
      .button:hover {
        background-color: $primary;
      }

      .input {
        padding: 0.1em;

        label {
          display: block;
          margin: 0px 0 10px 0;
          font-size: small !important;
        }

        textarea,
        input {
          margin: 0px 0 16px 0;
          padding: 10px;
          width: 100%;
          background-color: white;
          border-top: none;
          border-right: none;
          border-radius: 5px;
          border-left: none;
          outline: none;
          border: 1px solid #60606030;
          box-shadow: 1px 1px 5px #60606010;
          font-size: small;
        }
        textarea:focus,
        input:focus {
          border-bottom: 1px solid $primary;
        }
      }
      .select {
        padding: 0.1em;

        label {
          display: block;
          margin: 0px 0 10px 0;
          font-size: small !important;
        }

        select {
          margin: 0px 0 16px 0;
          padding: 10px;
          width: 100%;
          font-size: small;
          background-color: white;
          border-top: none;
          border-right: none;
          border-left: none;
          border-radius: 5px;
          outline: none;
          border: 1px solid #60606030;
          box-shadow: 1px 1px 5px #60606010;
        }
      }

      .hd {
        display: grid;
        grid-template-columns: 1fr auto;

        h3 {
          font-size: large;
        }

        .fa-times {
          color: $secondary;
          cursor: pointer;
        }
        .fa-times:hover {
          color: orange;
        }
      }
    }
  }
}
