@import "./mediaquery";
@import "./variables.scss";

.ubertask {
  position: relative;
  hr {
    color: #60606030 !important;
    margin: 10px 0 10px 0;
  }

  .pcontainer {
    border-radius: 4px;
    margin: 0 auto 0 auto;
    color: #000000;
    overflow-y: auto;
    width: 100%;
    position: relative;

    .tbar {
      background-color: $accent;
      padding: 16px !important;

      h3 {
        text-align: center;
        font-size: medium;
        color: white;
      }

      .fa {
        position: absolute;
        top: 10px;
        right: 10px;
        color: white;
        height: fit-content;
        margin: auto;
        padding: 5px;
      }
      .fa:hover {
        color: $accent;
      }
    }

    .wcontent {
      padding: 1em;
      position: relative;
      width: 100%;

      .pdiv {
        background-color: white;
        border-radius: 8px;
        margin: 0 0 1em 0;
        padding: 1em;
      }

      h3 {
        font-size: small;
        color: $primary;
      }

      h5 {
        color: $primary;
        cursor: pointer;
        font-size: xx-large;
        margin-top: 1em;
      }

      h6 {
        text-align: center;
        color: $accent;
        margin-top: 5px;
        font-weight: 500;
      }

      .u_list {
        box-shadow: 1px 1px 3px #60606010;
        border: 1px solid #60606010;
        border-radius: 2px;
        margin: 6px 0 6px 0;
        padding: 10px;
        position: relative;
        height: 100%;
        cursor: pointer;

        h3 {
          font-size: m;
          text-align: left;
        }

        .updateprp {
          margin-left: 2em;
          .up_wrapper {
            margin: auto;
            background-color: wheat;
            padding: 1em;
            box-shadow: 1px 1px 3px #60606010;
            border: 1px solid #60606010;
            border-radius: 5px;
            position: relative;

            .up_bar {
              display: flex;
              border-bottom: 1px solid orange;
              p {
                cursor: pointer;
                padding: 5px;
                font-size: small;
                margin: 0;
              }

              p:hover {
                background-color: orange;
              }

              .active {
                background-color: orange;
                color: white;
              }
            }

            .fa-times {
              color: $accent !important;
              cursor: pointer;
            }

            h6 {
              margin: 5px 0 5px 0;
              min-height: 16px;
              color: red;
              text-align: center;
              font-size: small;
              font-weight: 100;
            }

            .input {
              label {
                display: block;
                margin: 5px 0 5px 0;
                font-size: small;
              }

              input {
                margin: 0px 0 5px 0;
                padding: 5px;
                width: 100%;
                background-color: $bg;
                border-top: none;
                border-right: none;
                border-left: none;
                outline: none;
                border-bottom: 3px solid rgba($primary, 0.5);
              }
              input:focus {
                border-bottom: 2px solid $primary;
              }
              textarea {
                margin: 0px 0 5px 0;
                padding: 5px;
                width: 100%;
                background-color: $bg;
                border-top: none;
                border-right: none;
                border-left: none;
                outline: none;
                border-bottom: 3px solid rgba($primary, 0.5);
              }
              textarea:focus {
                border-bottom: 2px solid $primary;
              }
            }

            .select {
              label {
                display: block;
                margin: 10px 0 10px 0;
                font-size: medium;
              }

              select {
                margin: 0px 0 10px 0;
                padding: 10px;
                width: 100%;
                background-color: $bg1;
                border-top: none;
                border-right: none;
                border-left: none;
                outline: none;
                border-bottom: 3px solid rgba($primary, 0.5);
              }
              select:focus {
                border-bottom: 2px solid $primary;
              }
            }

            .usrinput {
              margin: 10px 0 10px 0;

              h4 {
                display: block;
                text-align: left;
                font-weight: 400;
                font-size: small;
                color: #000000 !important;
              }

              input {
                margin-top: 0px !important;
                padding: 10px;
                width: 100%;
                background-color: $bg1;
                border-top: none;
                border-right: none;
                border-left: none;
                outline: none;
                border-bottom: 3px solid rgba($primary, 0.5);
              }
              input:focus {
                border-bottom: 2px solid $primary;
              }
            }
          }
        }

        .idcheck {
          p {
            font-size: small;
          }
          select,
          input {
            padding: 10px;
            width: 100%;
            margin: 4px;
            border: 1px solid orange;
            outline: none;
            font-size: small;
            border-radius: 5px;
          }
        }

        .p_check {
          box-shadow: 1px 1px 3px #60606010;
          border: 1px solid #60606010;
          border-radius: 2px;
          margin: 6px 0 6px 0;
          padding: 5px;
          position: relative;
          cursor: pointer;
          display: grid;
          grid-template-columns: auto 1fr;
          gap: 10px;

          div {
            width: 24px;
            height: 24px;
            margin: auto !important;
            background-color: red;
            border-radius: 24px;
          }

          p {
            padding: 0 !important;
          }
        }

        // .p_check:hover {
        //   background-color: #fff5b8;
        // }

        hr {
          color: #60606010;
          margin-top: 5px;
        }

        .div2equal {
          gap: 1em !important;
        }

        .no {
          position: absolute;
          right: 5px;
          top: 0;
          bottom: 0;
          height: 100%;
          left: auto;
          display: flex;
          align-items: center;

          p {
            color: white;
            font-size: medium;
            font-weight: 600;
            text-align: center;
            padding: 5px 10px;
            border-radius: 24px;
            background-color: $accent;
          }
        }

        .fa-times {
          position: absolute;
          right: 10px;
          top: 10px;
          color: $accent;
          cursor: pointer;
        }

        h3,
        h4 {
          font-size: medium;
          font-weight: 500;
          text-align: center;
          color: $primary;
        }

        b {
          font-weight: 500;
        }

        p {
          font-size: medium;
          font-weight: 400;
          padding: 3px;
        }
      }

      .div3equal {
        gap: 10px !important;
      }

      .usrinput {
        margin: 10px 0 10px 0;

        h4 {
          display: block;
          margin-bottom: 10px !important;
          font-weight: 400;
          font-size: small;
        }

        input {
          margin-top: 10px;
          padding: 10px;
          width: 100%;
          background-color: $bg;
          border-top: none;
          border-right: none;
          border-left: none;
          outline: none;
          border-bottom: 3px solid rgba($primary, 0.5);
        }
        input:focus {
          border-bottom: 2px solid $primary;
        }
      }

      .search {
        position: relative;

        .search_list {
          position: absolute;
          left: 0;
          right: 0;
          top: 74px;
          padding: 5px;
          border: 1px solid #60606030;
          border-radius: 5px;
          box-shadow: 1px 1px 5px #60606030;
          z-index: 999;
          background-color: white;

          p {
            font-size: x-small;
            padding: 5px;
            cursor: pointer;
            margin-bottom: 3px;
          }
          p:hover {
            color: $secondary;
          }
        }
      }

      .selected_staff {
        box-shadow: 1px 1px 5px #60606030;
        border-radius: 5px;
        width: 100%;
        position: relative;
        padding: 10px;
        margin-top: 10px;

        h3,
        p {
          font-size: small;
        }

        .fa-times {
          position: absolute;
          right: 10px;
          top: 10px;
        }
      }

      button {
        display: block;
        margin: 10px auto 10px auto;
        padding: 4px;
        width: 80%;
        font-size: small;
        background-color: $primary;
        color: white;
        border-right: none;
        border-left: none;
        border-bottom: 3px solid rgba($primary, 0.5);
        border-top: 2px solid #60606070;
        cursor: pointer;
      }
      button:hover {
        background-color: rgba($color: $primary, $alpha: 0.8);
      }

      .div {
        box-shadow: 1px 1px 3px #60606010;
        border: 1px solid #60606010;
        border-radius: 2px;
        padding: 10px;
        min-height: 90%;
        height: 100%;
      }

      .prg {
        display: grid;
        grid-template-rows: repeat(5, 1fr);
        gap: 5px;
        height: 100%;

        .item {
          display: grid;
          grid-template-columns: 5px 1fr;
          gap: 10px;
          height: 100%;

          p {
            display: block;
            margin: auto;
            width: 100%;
          }

          div {
            height: 100%;
            width: 100%;
            background-color: orange;
          }
        }
      }

      h5 {
        font-size: medium;
        font-weight: 600;
        margin-bottom: 10px;
        line-height: 24px;
        height: 24px;
      }

      h4,
      p {
        margin: 4px 0 4px 0;
        font-size: small;
      }

      h4 {
        font-weight: 600;
      }

      .link {
        color: $primary;
        font-style: italic;
        margin-left: 10px;
        cursor: pointer;
      }
      .link:hover {
        color: $secondary;
      }
    }
  }
  .uberreport {
    width: 768px;
    border-radius: 5px;
    padding: 0.5in;
    background-color: white;

    .container {
      border: 5px double orange;
      padding: 10px;

      h3 {
        padding: 1em 0 10px 0;
        font-size: large;
        font-weight: 560;
      }
    }

    .passfail {
      display: grid;
      grid-template-columns: 1fr 180px;
      gap: 1em;
      margin-top: 2em;

      .pdiv {
        background-color: #f8f6ec;
        border-radius: 5px;
        height: 100%;
        display: flex;
        align-items: center;

        h6 {
          font-size: 64px;
          color: rgb(0, 255, 0);
          rotate: -10deg;
          font-weight: bolder;
          margin: auto;
        }
      }
    }

    .utop {
      display: grid;
      grid-template-columns: 1fr auto;
      gap: 1em;

      h1 {
        text-align: center;
        color: $primary;
        margin-bottom: 1em;
      }

      .logo {
        display: grid;
        grid-template-rows: 1fr auto;
        height: 200px;

        img {
          max-width: 100%;
          width: 100%;
          max-height: 200px;
          height: fit-content;
          object-fit: contain0;
        }
      }

      .capt {
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        gap: 10px;
        padding: 1em;

        h4 {
          color: $primary;
          font-size: X-large;
          font-style: italic;
        }

        hr {
          background-color: orange;
        }
      }

      .pass {
        max-width: 180px;
        width: 100%;
        max-height: 200px;
        object-fit: contain;
        object-position: top;
        border: 1px solid #60606030;
        aspect-ratio: 3/4; /* Set the correct aspect ratio */
      }
    }

    .ref {
      border: 1px solid #60606030;
      h4 {
        padding: 10px;
        color: $secondary;
        font-size: small;
        background-color: #f8f6ec;
        margin: 0 !important;
      }
      p {
        padding: 10px;
        color: gray;
        font-size: medium;
      }
    }

    .rpt {
      display: grid;
      grid-template-columns: repeat(4, 1fr);

      .id {
        border: 1px solid #60606030;
        h4 {
          padding: 10px;
          color: $secondary;
          font-size: small;
          background-color: #f8f6ec;
          margin: 0 !important;
        }
        p {
          padding: 10px;
          color: gray;
          font-size: medium;
        }
      }
    }

    .chk {
      .id {
        display: grid;
        grid-template-columns: 1fr 2fr;
        margin: 0 !important;
        border-radius: 2px;
        h4 {
          padding: 10px;
          color: $secondary;
          font-size: small;
          background-color: #f8f6ec;
          border: 1px solid #60606030;
          margin: 0 !important;
        }
        p {
          padding: 10px;
          color: gray;
          font-size: small;
          border: 1px solid #60606030;
          margin: 0 !important;
        }
      }
    }
  }

  @include maxwidth(mobile) {
    padding: 10px !important;

    .uberreport {
      display: none;
    }

    .welcome {
      grid-template-columns: 1fr !important;

      h1 {
        font-size: medium;
      }
    }

    .selector {
      h3 {
        font-size: small !important;
      }
    }

    .search {
      display: flex;
      grid-template-columns: 1fr !important;

      input {
        display: inline-block !important;
      }

      .fa {
        display: none !important;
      }
    }

    .accordion-body {
      grid-template-columns: 1fr !important;
      gap: 0 !important;

      .status {
        grid-template-columns: 1fr !important;
      }

      h4 {
        font-size: small !important;
        margin-top: 8px;
      }

      .date {
        display: flex;
        gap: 10px;

        p {
          font-size: medium !important;
          margin: auto !important;
          display: block;
        }
      }

      .list {
        grid-template-columns: 1fr !important;
        gap: 0 !important;
        padding: 0 !important;

        p {
          padding: 0 !important;
          margin-bottom: 3px;
        }
      }
    }
    .summary {
      grid-template-columns: 1fr !important;
      gap: 1em !important;

      .key {
        min-width: auto !important;
      }

      .chart {
        height: auto !important;
      }
    }

    .popup {
      position: fixed !important;

      .div3equal {
        .div {
          margin-bottom: 10px !important;
          min-height: auto !important;
        }
      }

      .pcontainer {
        max-width: 85vw !important;
        width: 100% !important;
        height: 100% !important;
        overflow-y: auto;

        .link {
          word-break: break-all !important;
        }
      }
    }

    h2,
    h3,
    p,
    h4,
    h5,
    label,
    h6 {
      font-size: small !important;
    }

    .div3equal,
    .div2equal {
      grid-template-columns: 1fr !important;
      gap: 1em !important;
    }
  }
  @include maxwidth(tablet) {
    padding: 10px !important;

    .uberreport {
      display: none;
    }
    .welcome {
      grid-template-columns: 1fr !important;

      h1 {
        font-size: medium;
      }
    }

    .selector {
      h3 {
        font-size: small !important;
      }
    }

    .search {
      display: flex;
      grid-template-columns: 1fr !important;

      input {
        display: inline-block !important;
      }

      .fa {
        display: none !important;
      }
    }

    .accordion-body {
      grid-template-columns: 1fr !important;
      gap: 0 !important;

      .status {
        grid-template-columns: 1fr !important;
      }

      h4 {
        font-size: small !important;
        margin-top: 8px;
      }

      .date {
        display: flex;
        gap: 10px;

        p {
          font-size: medium !important;
          margin: auto !important;
          display: block;
        }
      }

      .list {
        grid-template-columns: 1fr !important;
        gap: 0 !important;
        padding: 0 !important;

        p {
          padding: 0 !important;
          margin-bottom: 3px;
        }
      }
    }
    .summary {
      grid-template-columns: 1fr !important;
      gap: 1em !important;

      .key {
        min-width: auto !important;
      }

      .chart {
        height: auto !important;
      }
    }

    .popup {
      position: fixed !important;

      .div3equal {
        .div {
          margin-bottom: 10px !important;
          min-height: auto !important;
        }
      }

      .pcontainer {
        max-width: 85vw !important;
        width: 100% !important;
        height: 100% !important;
        overflow-y: auto;

        .link {
          word-break: break-all !important;
        }
      }
    }

    h2,
    h3,
    p,
    h4,
    h5,
    label,
    h6 {
      font-size: small !important;
    }

    .div3equal,
    .div2equal {
      grid-template-columns: 1fr !important;
      gap: 1em !important;
    }
  }
}
