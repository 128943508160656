@import "./_variables";
@import "./mediaquery";

.header1 {
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  background-color: white;
  box-shadow: 1px 1px 5px #60606030;

  .contacts {
    border-bottom: 1px solid #60606030;
    padding: 10px 0 1em 0;

    .ccontainer {
      display: grid;
      grid-template-columns: 1fr auto auto auto;
      gap: 1em;
      max-width: 85%;
      width: 100%;
      margin: auto;

      div {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 1em;
        width: fit-content;

        img {
          display: block;
          margin: auto;
          max-height: 44px;
          height: 100%;
          object-fit: contain;
        }
      }

      h1 {
        color: $primary;
        margin: auto;
        font-size: large;
      }

      a {
        text-decoration: none;
        color: gray;
        font-size: small;
        display: block;
        margin: auto;
      }
    }
  }

  .hcontainer {
    max-width: 85%;
    width: 100%;
    margin: auto;
    position: relative;

    .buttons {
      position: absolute;
      left: auto;
      right: 0;
      top: 0;
      bottom: 0;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;

      button {
        background-color: orange;
        color: white;
        border: 1px solid orange;
        border-radius: 5px;
        padding: 10px 1em 10px 1em;
        cursor: pointer;
        margin: auto;
        height: fit-content;
      }

      button:hover {
        border: 1px solid orange;
        color: orange;
        background-color: transparent;
      }
    }

    .wrapper {
      width: fit-content;
      margin: auto;
      padding: 1em;
      gap: 2em;
      display: block;
      grid-template-columns: repeat(6, auto);

      a {
        text-decoration: none;
        font-size: medium;
        color: $secondary;
        padding: 10px;
        cursor: pointer;
      }

      a:hover {
        color: $primary;
      }

      .active {
        color: $primary;
      }
    }

    .mob {
      display: none;
      margin: auto 0 auto auto;
      padding: 10px 0 10px 0;

      i {
        font-size: large;
        width: fit-content;
        text-align: center;
        color: $primary;
        margin: auto 0 auto auto;
        display: block;
        height: 30px;
        line-height: 30px;
      }
    }

    @include maxwidth(mobile) {
      .right {
        display: none;
      }

      .mob {
        display: block;
        order: 0;
      }
    }

    @include maxwidth(tablet) {
      .right {
        display: none;
      }

      .mob {
        display: block;
        order: 0;
      }
    }
  }

  @include maxwidth(mobile) {
    position: relative !important;

    .contacts {
      .container {
        display: block !important;
      }

      img {
        max-width: 40vw !important;
        width: 100% !important;
      }

      a {
        display: none !important;
      }
    }

    .hcontainer {
      display: grid;
      grid-template-columns: 1fr auto;

      .buttons {
        display: none !important;
      }

      .wrapper {
        display: none !important;
      }
    }
  }

  @include maxwidth(tablet) {
    .contacts {
      .container {
        display: block !important;
      }

      img {
        max-width: 40vw !important;
        width: 100% !important;
      }

      a {
        display: none !important;
      }
    }

    .hcontainer {
      display: grid;
      grid-template-columns: 1fr auto;

      .buttons {
        display: none !important;
      }
    }
  }
}

.header {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 10px;
  padding: 10px;
  background-color: $primary;
  color: white;

  .right {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 10px;

    p {
      font-size: small;
      display: flex;
      align-items: center;

      .user {
        margin-right: 5px;
        height: 24px;
        width: 24px;
        padding: 2px;
      }

      span {
        margin-left: 10px;
      }

      b {
        font-weight: 520;
      }
    }
  }
}