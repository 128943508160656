@import "./mediaquery";
@import "./variables";

.landing {
  height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;

  .landing_hero {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    min-height: 500px;

    .nhero {
      background-color: rgba($color: #000000, $alpha: 0.5);
      z-index: 999;
      height: 100%;
      min-height: 500px;

      .slideshow-container {
        position: relative;
        height: 100%;
        min-height: 500px;
      }

      .slideshow-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        min-height: 500px;
        opacity: 0;
        transition: opacity 0.5s ease-in-out;

        img {
          max-height: 100%;
          min-height: 500px;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }

        .content {
          position: absolute;
          top: 0;
          left: 7.5vw;
          right: 50vw;
          height: 100%;
          display: flex;
          align-items: center;
          background-color: rgba(0, 0, 0, 0.7);
          padding: 2em;
        }

        .left.animation-active {
          opacity: 1;
          transform: translateY(0);
        }

        .left {
          display: grid;
          grid-template-rows: auto 1fr auto;
          gap: 1em;
          text-align: center;
          color: white;
          height: 100%;
          padding: 2em 0 2em 0 !important;
          opacity: 0;
          transform: translateY(50px);
          transition: transform 0.5s ease;

          p {
            padding: 0 0 44px 0;
            position: relative;
            text-align: left !important;
            width: fit-content;
            font-weight: 600;
          }

          p::before {
            content: "";
            position: absolute;
            left: 0%;
            bottom: 16px;
            max-width: 150px;
            width: 100%;
            height: 14px;
            transform: skew(-45deg) translateX(-50%);
            background: orange;
            z-index: -1;
          }

          h1 {
            margin: auto;
            font-size: xx-large;
            color: white;
            text-align: center;
            letter-spacing: 5px;
          }

          p {
            font-size: x-large !important;
            color: white;
          }

          button {
            background-color: orange;
            color: white;
            border: 1px solid orange;
            border-radius: 5px;
            padding: 10px 1em 10px 1em;
            cursor: pointer !important;
            margin: auto;
            height: fit-content;
          }
          button:hover {
            border: 1px solid orange;
            color: orange !important;
            background-color: transparent !important;
          }
        }
      }

      .slideshow-image.active {
        opacity: 1;
      }
    }

    @include maxwidth(mobile) {
      .content {
        right: 7.5vw !important;
        .left {
          height: fit-content !important;
          grid-template-rows: auto 1fr auto !important;

          p {
            font-size: x-small !important;
            height: fit-content !important;
          }

          h1 {
            font-size: x-large !important;
          }
        }
      }
    }
    @include maxwidth(tablet) {
      .content {
        right: 7.5vw !important;
        .left {
          height: fit-content !important;
          grid-template-rows: auto 30vh auto !important;

          p {
            font-size: x-small !important;
            height: fit-content !important;
          }

          h1 {
            font-size: x-large !important;
          }
        }
      }
    }
  }
}


.terms {
  height: 100vh;
  overflow-y: auto;

  .pcontent {
    max-width: 85%;
    width: 100%;
    margin: 2em auto 2em auto;

    p {
      font-weight: 400;
      line-height: 1.5;
      text-align: justify;
    }

    p,
    h1 {
      padding: 10px 0 10px 0;
    }

    h2 {
      font-size: medium;
      font-weight: 600;
      margin-top: 1em;
    }

    li {
      padding: 3px 0 3px 0;
      margin-left: 1em;
    }
  }
}

@keyframes zoom {
  0% {
    scale: 0.8;
  }
  100% {
    scale: 1;
  }
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
