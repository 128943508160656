@import "./mediaquery";
@import "./variables";

.login {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color: #000000, $alpha: 0.1);
  display: flex;
  align-items: center;
  z-index: 99999;
  height: 100%;
  overflow-y: auto;

  .container {
    background-color: white;
    min-width: 30%;
    max-width: 85%;
    width: fit-content;
    height: fit-content;
    border-radius: 8px;
    box-shadow: 1px 1px 5px #60606030;
    border: 1px solid #60606030;
    padding: 10px 2em;
    position: relative;
    margin: 2em 7.5% 2em auto;

    .fa-times {
      position: absolute;
      top: 1em;
      right: 1em;
      color: orange;
      cursor: pointer;
    }

    span {
      color: orange;
      cursor: pointer;
    }

    p {
      font-size: medium;
      font-weight: 400;
      text-align: center;
      margin: 1em 0 1em 0 !important;

      a {
        color: orange;
      }
    }

    h6 {
      font-size: small;
      font-weight: 400;
      color: orange;
      text-align: center;
      height: 12px;
      line-height: 12px;
      margin-bottom: 8px;
    }

    h3 {
      font-size: x-large;
      font-weight: 600;
      color: $primary;
      text-align: center;
      padding: 10px;
    }

    button {
      display: block;
      margin: 0 auto 10px auto;
      padding: 8px;
      width: 80%;
      background-color: $primary;
      color: white;
      border-top: none;
      font-size: medium !important;
      border-right: none;
      border-left: none;
      border-bottom: 3px solid rgba($primary, 0.5);
      border-top: 2px solid #60606070;
      cursor: pointer;
    }
    button:hover {
      background-color: rgba($color: $primary, $alpha: 0.8);
    }

    .select {
      margin: 10px 0 10px 0;

      label {
        display: block;
        margin: 10px 0 10px 0;
        font-weight: 400;
      }

      select {
        margin: 0px 0 10px 0;
        padding: 10px;
        width: 100%;
        background-color: $bg;
        border-top: none;
        border-right: none;
        border-left: none;
        outline: none;
        border-bottom: 3px solid rgba($primary, 0.5);
      }
      input:focus {
        border-bottom: 2px solid $primary;
      }
    }

    .input {
      margin: 10px 0 10px 0;
      width: 100%;

      label {
        display: block;
        margin: 10px 0 10px 0;
        font-weight: 400;
      }

      input {
        margin: 0px 0 10px 0;
        padding: 10px;
        width: 100%;
        background-color: $bg;
        border-top: none;
        border-right: none;
        border-left: none;
        outline: none;
        border-bottom: 3px solid rgba($primary, 0.5);
      }
      input:focus {
        border-bottom: 2px solid $primary;
      }
    }
  }

  @include maxwidth(mobile) {
    .container {
      width: 100%;
      max-height: 90vh;
      overflow-y: auto;
    }
  }
  @include maxwidth(tablet) {
    .container {
      width: 100%;
      max-height: 90vh;
      overflow-y: auto;
    }
  }
}
