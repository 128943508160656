@import "./mediaquery";
@import "./variables";

.stats {
  height: fit-content !important;
  display: grid;
  grid-template-rows: auto 1fr auto;

  .welcome {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 1em;

    .refresh {
      width: 32px;
      height: 32px;
      color: $accent;
      cursor: pointer;
      padding: 5px;
      background-color: white;
      border-radius: 5px;
    }
    .refresh:hover {
      color: $primary;
    }

    h1 {
      font-size: large;
      font-weight: 560;
      margin-bottom: 10px;
    }

    .search {
      display: grid;
      grid-template-columns: auto 1fr auto;
      gap: 10px;

      .select {
        select {
          margin: auto;
          background-color: #60606020;
          padding: 1em;
          border: none;
          outline: none;
          border-radius: 44px;
          height: 44px;
        }
      }
      input {
        background-color: #60606020;
        padding: 1em;
        border: none;
        outline: none;
        border-radius: 44px;
        height: 44px;
      }
      i {
        padding: 1em;
        color: orange;
        height: 44px;
        margin-left: -54px;
      }
      input:focus {
        outline: 1px solid orange;
      }
    }
  }

  .taskstats {
    display: grid;
    grid-template-columns: 2.5fr 1fr;
    gap: 1em;
    margin-top: 1em;
    margin-bottom: 1em;

    .bar {
      display: grid;
      grid-template-columns: 10px 1fr;
      background-color: white;
      box-shadow: 1px 1px 1px $primary;

      div {
        width: 100%;
        height: 100%;
        background-color: $accent;
      }

      h2 {
        font-weight: 600;
        padding: 8px;
        font-size: medium;
        color: $primary;
      }
    }

    .section {
      width: 100%;
      height: 100%;
      background-color: white;
      box-shadow: 1px 1px 3px #60606030;
      padding: 10px;

      h3 {
        text-align: center;
        margin-bottom: 10px;
        font-size: medium;
        font-weight: 500;
        color: gray;
      }

      .gauge {
        display: grid;
        grid-template-rows: auto 1fr;
        height: 100%;

        .gg {
          display: block;
          margin-top: auto;
        }
      }

      .single {
        display: grid;
        grid-template-columns: auto 1fr;
        place-content: center;
        gap: 10px;
        margin: auto;

        .ts {
          width: 44px;
          height: 44px;
          color: $accent;
          display: block;
          margin: auto;
        }

        h4 {
          font-size: x-large;
          font-weight: 900;
          color: gray;
          text-align: right;
          margin-bottom: 4px;
        }
        p {
          font-size: small;
          color: gray;
          text-align: right;
        }
      }

      .perf {
        display: grid;
        grid-template-rows: 1fr auto auto auto;
        gap: 10px;
        height: 100%;
        width: 100%;

        h2 {
          font-size: medium;
          font-weight: 520;
        }

        .hl {
          margin: auto;
          h4 {
            color: red;
            font-size: xx-large;
            font-weight: bolder;
            margin-bottom: 10px;
            text-align: center;
          }
          h5 {
            font-weight: 500;
            color: gray;
            text-align: center;
          }
        }

        .div1auto {
          width: 100%;
          display: grid;
          grid-template-columns: 10px 1fr auto;
          gap: 10px;
          padding: 8px;

          div {
            width: 100%;
            height: 100%;
          }

          p {
            font-size: medium;
          }
          h6 {
            font-size: large;
            font-weight: bolder;
            color: gray;
          }
        }
      }
    }

    .left {
      display: grid;
      grid-template-rows: auto 1fr;
      gap: 1em;

      .outer {
        display: grid;
        grid-template-columns: 1fr 2fr;
        gap: 1em;

        .ll {
          display: grid;
          grid-template-rows: 1fr 1fr 1fr 1fr;
          gap: 1em;
        }
      }
    }
    .right {
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-rows: auto 1fr;
      gap: 1em;
      .outer {
        height: 100%;
      }
    }
  }

  .chart {
    background-color: white;
    height: 100%;
    min-height: 250px;
    width: 100%;
    border-radius: 8px;
    padding: 1em;
    position: relative;

    h3 {
      color: gray;
      font-size: medium;
      margin-bottom: 1em;
      font-weight: 600;
    }

    .save {
      position: absolute;
      right: 16px;
      top: 16px;
      display: grid;
      grid-template-columns: auto auto;
      gap: 10px;

      * {
        cursor: pointer;
      }
    }
  }

  .top {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    width: 100%;

    .tp_item {
      padding: 1em;
      background-color: white;
      border-radius: 8px;

      p {
        color: gray;
        font-size: small;
      }

      h1 {
        color: gray;
        font-weight: bolder;
        margin: 5px 0 5px 0;
        font-size: xx-large;
      }
    }
  }

  .pies {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1em;
    margin: 1em 0 1em 0;
  }

  .middle {
    height: fit-content !important;
    margin: 1em 0 1em 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
  }

  .bottom {
    height: fit-content !important;
    margin: 1em 0 1em 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
  }

  @include maxwidth(mobile) {
    h3 {
      font-size: small !important;
    }

    .top {
      grid-template-columns: repeat(2, 1fr) !important;
    }

    .pies {
      grid-template-columns: 1fr !important;
    }

    .bottom {
      grid-template-columns: 1fr !important;
    }

    .middle {
      grid-template-columns: 1fr !important;
    }
  }

  @include maxwidth(tablet) {
    h3 {
      font-size: medium !important;
    }

    .top {
      grid-template-columns: repeat(2, 1fr) !important;
    }

    .pies {
      grid-template-columns: 1fr !important;
    }

    .bottom {
      grid-template-columns: 1fr !important;
    }

    .middle {
      grid-template-columns: 1fr !important;
    }
  }
}
