@import "./mediaquery";
@import "./variables";

.payroll {
  height: fit-content !important;

  .leading {
    padding: 1em 0;
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 1em;

    h1 {
      color: $primary;
      font-size: x-large;
    }
    button {
      padding: 8px 1em;
      background-color: $primary;
      color: white;
      border: 1px solid $primary;
      cursor: pointer;
      border-radius: 5px;
      box-shadow: 1px 1px 5px #60606010;
      transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out;
    }
    button:hover {
      color: $primary;
      background-color: transparent;
    }
  }

  .container {
    background-color: white;
    padding: 1em;
    border-radius: 5px;
    box-shadow: 1px 1px 5px #60606030;

    .top {
      display: grid;
      grid-template-columns: 3fr 1fr 1fr;
      gap: 1em;

      .items-list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1em;

        .item {
          padding: 8px 1em;
          border-radius: 5px;
          border: 1px solid #60606010;
          box-shadow: 1px 1px 5px #60606010;
          position: relative;
          h4 {
            color: $primary;
            font-size: smaller;
            font-weight: 400;
            margin-top: 10px;
          }

          p {
            font-size: small;
            color: gray;
          }

          h1 {
            color: $accent;
            font-size: larger;
            padding: 10px 0;
          }

          img {
            position: absolute;
            top: 10px;
            right: 10px;
            height: 32px;
            width: 32px;
            object-fit: contain;
            display: block;
            margin-left: auto;
          }
        }
      }

      .pay {
        padding: 8px 1em;
        border-radius: 5px;
        border: 1px solid #60606010;
        box-shadow: 1px 1px 5px #60606010;
        text-align: center;

        img {
          height: 64px;
          width: 64px;
          object-fit: contain;
          display: block;
          margin: 1em auto;
        }
        h4 {
          font-size: large;
          margin: 8px 0;
          color: #777777;
        }
        p {
          color: #686868;
          font-size: small;
          margin: 8px 0;
        }
        h6 {
          color: #686868;
          font-size: x-small;
          margin: 8px 0;
          font-weight: 400;
        }
        button {
          margin-top: 10px;
          padding: 8px 1em;
          background-color: $secondary;
          color: white;
          border: 1px solid $secondary;
          cursor: pointer;
          border-radius: 5px;
          box-shadow: 1px 1px 5px #60606010;
          transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out;
        }
        button:hover {
          color: $secondary;
          background-color: transparent;
        }
      }
    }

    .chart {
      background-color: white;
      height: 100%;
      min-height: 250px;
      width: 100%;
      padding: 1em;
      position: relative;
      border-radius: 5px;
      border: 1px solid #60606010;
      box-shadow: 1px 1px 5px #60606010;

      h3 {
        color: gray;
        font-size: medium;
        margin-bottom: 1em;
        font-weight: 600;
      }

      .save {
        position: absolute;
        right: 16px;
        top: 16px;
        display: grid;
        grid-template-columns: auto auto;
        gap: 10px;

        * {
          cursor: pointer;
        }
      }
    }

    .intro {
      margin: 1em 0;

      h4 {
        color: #686868;
        font-size: medium;
        font-weight: 520;
        margin: 10px 0;
      }

      .div1auto {
        display: grid;
        grid-template-columns: 1fr auto auto;
        gap: 1em;

        select {
          padding: 8px;
          border: 1px solid #60606030;
          border-radius: 5px;
          outline: none;
          height: fit-content;
          background-color: transparent;
          margin-top: auto;
        }

        .search {
          position: relative;
          height: fit-content;

          input {
            padding: 8px 8px 8px 2.5em;
            border: 1px solid #60606030;
            border-radius: 5px;
            outline: none;
          }

          .ic {
            position: absolute;
            left: 10px;
            top: 0;
            bottom: 0;
            height: 100%;
            line-height: 100%;
            color: #606060;
            display: grid;
            place-content: center;
          }
        }

        button {
          margin-top: 10px;
          padding: 8px 1em;
          background-color: $secondary;
          color: white;
          border: 1px solid $secondary;
          cursor: pointer;
          border-radius: 5px;
          box-shadow: 1px 1px 5px #60606010;
          transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out;
        }
        button:hover {
          color: $secondary;
          background-color: transparent;
        }
      }
    }

    .list {
      .head {
        border: 1px solid #60606010;
        display: grid;
        grid-template-columns: 32px 1fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.3fr;

        h4 {
          width: 100%;
          padding: 8px;
          color: $primary;
          font-weight: 500;
          font-size: smaller;
          margin: auto;
          display: block;
        }
      }
      .row {
        border: 1px solid #60606010;
        display: grid;
        grid-template-columns: 32px 1fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.3fr;
        cursor: pointer;
        transition: background-color 0.5s ease-in-out;

        p {
          padding: 8px;
          color: #686868;
          font-weight: 500;
          font-size: smaller;
          margin: auto;
          display: block;
          width: 100%;
        }
        :last-child {
          color: $primary;
        }
        .ic {
          color: $primary;
          display: block;
          margin: auto;
          font-size: larger;
        }

        .paid {
          background-color: rgba(0, 128, 0, 0.1);
          color: green;
          padding: 4px 1em;
          text-align: center;
          border-radius: 30px;
          width: fit-content;
          margin: auto 0;
          font-size: small;
        }
        .not-paid {
          background-color: rgba(255, 68, 0, 0.1);
          color: orangered;
          padding: 4px 1em;
          text-align: center;
          border-radius: 30px;
          width: fit-content;
          margin: auto 0;
          font-size: small;
        }
      }

      .row:hover {
        background-color: #60606010;
      }
    }
  }

  .payslip {
    background-color: white;
    width: 100%;
    margin: auto;
    border-radius: 8px;

    .top {
      background-color: $primary;
      color: white;
      display: grid;
      grid-template-columns: 1fr auto auto;
      gap: 1em;
      padding: 4px 10px;
      border-radius: 5px 5px 0 0;

      h2 {
        font-size: medium;
        margin: auto;
        display: block;
        width: 100%;
        font-weight: 520;
      }

      button {
        background-color: transparent;
        border: 1px solid white;
        color: white;
        cursor: pointer;
        border-radius: 5px;
        padding: 5px 1em;
        transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out,
          border-radius 0.5s ease-in-out;
      }
      button:hover {
        background-color: white;
        color: $primary;
        border-radius: 0;
      }
    }

    .divsplit {
      display: grid;
      grid-template-columns: 2fr 1fr;
      gap: 1em;
      background-color: white;

      .iright_panel {
        padding: 1em;

        .links {
          background-color: white;
          border-radius: 5px;
          padding: 1em;
          border: 1px solid #60606010;
          box-shadow: 1px 1px 5px #60606010;

          .ic {
            position: absolute;
            top: 0;
            bottom: 0;
            height: 100%;
            left: 10px;
            margin-right: 1em;
            display: grid;
            place-content: center;
          }

          button {
            padding: 8px 1em;
            border: 1px solid $primary;
            border-radius: 5px;
            cursor: pointer;
            background-color: $primary;
            transition: background-color 0.5s ease-in-out,
              color 0.5s ease-in-out;
            width: 100%;
            color: white;
            margin: 10px 0;
            position: relative;
          }
          button:first-child {
            background-color: $secondary;
            border: 1px solid $secondary;
          }
          button:last-child {
            background-color: $primarylight;
            border: 1px solid $primarylight;
          }
          button:hover {
            background-color: transparent;
            color: $primary;
          }
        }

        .c_payments {
          background-color: white;
          border-radius: 5px;
          padding: 1em;
          border: 1px solid #60606010;
          box-shadow: 1px 1px 5px #60606010;
          margin: 1em 0;
          height: fit-content;

          h3 {
            font-size: medium;
            font-weight: 520;
            color: $primary;
          }

          hr {
            border: 1px solid #60606030;
            margin: 8px 0;
          }

          .pitem {
            display: grid;
            grid-template-columns: auto 1fr auto;
            gap: 10px;
            background-color: white;
            border-radius: 5px;
            padding: 10px;
            border: 1px solid #60606010;
            box-shadow: 1px 1px 5px #60606010;
            margin-bottom: 10px;
            position: relative;
            cursor: pointer;
            transition: background-color 0.5s ease-in-out;

            .fa-times {
              position: absolute;
              top: 2px;
              right: 2px;
              color: $accent;
              cursor: pointer;
              transition: color 0.5s ease-in-out;
            }

            .fa-times:hover {
              color: red;
            }

            .no {
              height: 32px;
              width: fit-content;
              padding: 10px 1em;
              border-radius: 32px;
              background-color: antiquewhite;
              border: 1px solid #60606010;
              display: grid;
              place-content: center;
            }

            h4 {
              font-size: smaller;
              font-weight: 500;
            }

            p {
              font-size: x-small;
              color: #686868;
            }

            h5 {
              display: block;
              margin: auto;
              padding: 4px 10px;
              border-radius: 24px;
              background-color: rgba(0, 128, 0, 0.2);
              color: green;
              font-weight: 400;
              font-size: smaller;
            }
          }
          .pitem:hover {
            background-color: wheat;
          }
        }
      }
    }

    .inv_preview {
      background-color: white;
      position: relative;
      padding: 1em;
      max-width: 650px;
      width: 100%;
      overflow-x: auto;
      margin: auto;

      .invoice {
        max-width: 595px;
        background-color: white;
        width: 100%;
        height: 100%;
        margin: auto;
        border-radius: 5px;
        box-shadow: 2px 2px 10px #60606050;
        padding-bottom: 74px;

        .bar {
          display: grid;
          grid-template-columns: 3fr auto 54px;
          place-items: center;
          gap: 1em;

          .invtop {
            padding: 1em 2em;
            width: 100%;

            img {
              max-width: 200px;
              width: 100%;
              object-fit: contain;
            }

            h2 {
              font-weight: 600;
              margin-top: 10px;
              font-size: medium;
            }

            p {
              line-height: 1.3;
            }
          }

          .div {
            height: 80%;
            width: 100%;
            background-image: linear-gradient(
              $primary 0%,
              $primarylight 30%,
              $primarylighter 50%,
              $primarylight 70%,
              $primary 100%
            );
          }

          h1 {
            font-size: 44px;
            height: 44px;
            line-height: 44px;
            color: $primary;
          }
        }

        .invheader {
          display: grid;
          grid-template-columns: 2fr 1fr;
          gap: 2em;
          padding: 0 2em 0 2em;

          .left {
            .enclose {
              padding: 10px;
              border: 1px solid #60606050;
              border-radius: 5px;
              height: 100%;

              hr {
                color: #60606020 !important;
                margin: 8px 0 8px 0;
              }
            }

            .div1auto {
              display: grid;
              grid-template-columns: 1fr auto;
              width: fit-content;
              gap: 10px;
              margin-bottom: 5px;
            }

            p {
              font-size: medium;
            }

            h4 {
              font-weight: 510;
              font-size: smaller;
            }
          }

          .right {
            width: 100%;
            height: fit-content;
            margin: auto;

            .div1auto {
              display: grid;
              grid-template-columns: 1fr auto;
              margin-bottom: 5px;
            }

            p {
              font-size: medium;
            }

            h4 {
              font-weight: 510;
              font-size: smaller;
            }
          }
        }

        .section {
          border: 1px solid #60606030;
          border-radius: 5px;
          margin: 1em 2em 1em 2em;
          min-height: 250px;

          .tphead {
            display: grid;
            grid-template-columns: 2fr 1fr;
            background-color: #fdf0f0;

            h3 {
              padding: 5px;
              font-size: small;
              font-weight: 520;
              text-align: left;
              width: 100%;
            }

            :nth-child(2) {
              text-align: center;
            }

            :nth-child(3),
            :nth-child(4) {
              text-align: right;
            }
          }

          .div2equal {
            margin: 0 !important;
            gap: 0 !important;
            grid-template-columns: 2fr 1fr !important;

            h3 {
              background-color: antiquewhite;
              color: $primary;
              font-size: smaller;
              font-weight: 520;
              border: 1px solid #60606010;
              height: 100%;
              padding: 4px;
            }

            h4 {
              background-color: rgb(252, 246, 239);
              font-size: smaller;
              font-weight: 500;
              border: 1px solid #60606010;
              height: 100%;
              padding: 4px;
            }

            p {
              background-color: white;
              font-size: smaller;
              font-weight: 500;
              border: 1px solid #60606010;
              height: 100%;
              padding: 4px;
              width: 100%;
              text-align: right;
              display: grid;
              align-items: center;
              // place-content: center;
            }

            input {
              background-color: white;
              font-weight: 500;
              border: 1px solid #60606010;
              padding: 4px;
              height: 100%;
              width: 100%;
              text-align: right;
              display: grid;
              place-content: center;
              outline: none;
            }
          }
        }

        .totals {
          padding: 1em 0 0 2em;

          .div1auto {
            display: grid;
            grid-template-columns: 1fr auto;
            gap: 1em;
          }

          .tdiv {
            display: grid;
            grid-template-columns: 1fr auto;
            gap: 10px;
            padding-right: 2em;
          }

          .tt {
            background-image: linear-gradient(
              to right,
              $primary 0%,
              $primarylight 30%,
              $primarylighter 50%,
              $primarylight 70%,
              $primary 100%
            );
            padding: 5px 2em 5px 10px;
            color: white;
            font-weight: 520;
            margin: 10px 0 10px 0;

            * {
              font-size: medium !important;
            }
          }

          p,
          h4 {
            margin: 4px 0 4px 0;
          }

          p {
            font-size: small;
          }

          h4 {
            font-weight: 520;
            font-size: small;
          }
        }

        .payment {
          padding: 0 2em;

          p,
          h4 {
            margin: 4px 0 4px 0;
          }

          p {
            font-size: small;
          }

          h4 {
            font-weight: 520;
            font-size: small;
          }
        }
      }
      .invfooter {
        padding: 1em 0;
        margin-top: -70px;

        .fbars {
          display: grid;
          grid-template-columns: 4fr auto 1fr;
          gap: 1em;

          h6 {
            font-weight: 500;
            color: $primary;
          }

          div {
            height: 2px;
            width: 100%;
            background-color: $accent;
            display: block;
            margin: auto;
          }
        }

        .contacts {
          display: grid;
          grid-template-columns: auto auto auto;
          padding: 10px 2em 0 2em;
          width: fit-content;

          p {
            padding-left: 10px;
            padding-right: 10px;
          }

          * {
            font-size: small;
            color: gray;
          }

          :nth-child(2) {
            border-left: 1px solid #60606030;
            border-right: 1px solid #60606030;
          }
        }
      }

      .watermark {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: #60606030;
      }
    }
  }

  .popup {
    padding: 2em;

    .wrap {
      background-color: white;
      max-width: 60vw;
      width: 100%;
      margin: auto;
      border-radius: 8px;
      overflow-y: auto;
      height: 100%;

      .bar {
        background-color: $primary;
        display: grid;
        grid-template-columns: 1fr auto;
        padding: 10px;
        h4 {
          color: white;
          font-weight: 500;
        }
      }
      form {
        padding: 1em;

        h6 {
          color: $accent;
          text-align: center;
          margin: 10px 0;
          font-weight: 500;
          font-size: medium;
        }

        h4 {
          font-weight: 500;
          margin-bottom: 8px;
        }

        .checkbox {
          display: grid;
          grid-template-columns: auto 1fr;
          gap: 10px;
          margin: 8px 0;
          font-size: small;

          input {
            border-radius: 5px;
          }
        }

        button {
          padding: 8px 1em;
          background-color: $primary;
          color: white;
          border: 1px solid $primary;
          cursor: pointer;
          border-radius: 5px;
          display: block;
          margin: auto;
          min-width: 200px;
          box-shadow: 1px 1px 5px #60606010;
          transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out;
        }
        button:hover {
          color: $primary;
          background-color: transparent;
        }
      }
    }

    .mrap {
      background-color: white;
      max-width: 40vw;
      width: fit-content;
      margin: auto;
      border-radius: 8px;

      h6 {
        color: $accent;
        text-align: center;
        margin: 10px 0;
        font-weight: 500;
        font-size: medium;
      }

      button {
        padding: 8px 1em;
        background-color: $primary;
        color: white;
        border: 1px solid $primary;
        cursor: pointer;
        border-radius: 5px;
        display: block;
        margin: auto;
        min-width: 200px;
        box-shadow: 1px 1px 5px #60606010;
        transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out;
      }
      button:hover {
        color: $primary;
        background-color: transparent;
      }

      .bar {
        background-color: $primary;
        display: grid;
        grid-template-columns: 1fr auto;
        padding: 10px;
        h4 {
          color: white;
          font-weight: 500;
        }
      }
      .section {
        padding: 1em;
        .div2equal {
          margin: 0 !important;
          gap: 0 !important;
          grid-template-columns: 2fr 1fr !important;

          h3 {
            background-color: antiquewhite;
            color: $primary;
            font-size: smaller;
            font-weight: 520;
            border: 1px solid #60606010;
            height: 100%;
            padding: 4px;
          }

          h4 {
            background-color: rgb(252, 246, 239);
            font-size: smaller;
            font-weight: 500;
            border: 1px solid #60606010;
            height: 100%;
            padding: 4px;
          }

          p {
            background-color: white;
            font-size: smaller;
            font-weight: 500;
            border: 1px solid #60606010;
            height: 100%;
            padding: 4px;
            width: 100%;
            text-align: right;
            display: grid;
            align-items: center;
            // place-content: center;
          }

          input {
            background-color: white;
            font-weight: 500;
            border: 1px solid #60606010;
            padding: 4px;
            height: 100%;
            width: 100%;
            text-align: right;
            display: grid;
            place-content: center;
            outline: none;
          }
        }
      }
    }
  }
}
