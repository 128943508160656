@import "./_variables";
@import "./mediaquery";

.footer {
  padding: 10px 0 10px 0;
  background-color: rgba($color: $primary, $alpha: 1);

  p {
    font-size: small;
    color: white;
    text-align: center;
  }
}
