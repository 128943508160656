@import "./mediaquery";
@import "./variables.scss";

.requests {
  height: 100%;
  width: 100%;
  overflow-y: auto !important;
  position: relative;

  .welcome {
    border-bottom: 1px solid #60606030;
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 1em;

    .refresh {
      width: 32px;
      height: 32px;
      color: $accent;
      cursor: pointer;
      padding: 5px;
      background-color: white;
      border-radius: 5px;
    }

    .refresh:hover {
      color: $primary;
    }

    h1 {
      font-size: large;
      font-weight: 560;
      margin-bottom: 10px;
    }

    .search {
      display: grid;
      grid-template-columns: auto 1fr auto;
      gap: 10px;

      .select {
        select {
          margin: auto;
          background-color: #60606020;
          padding: 1em;
          border: none;
          outline: none;
          border-radius: 44px;
          height: 44px;
        }
      }

      input {
        background-color: #60606020;
        padding: 1em;
        border: none;
        outline: none;
        border-radius: 44px;
        height: 44px;
      }

      i {
        padding: 1em;
        color: orange;
        height: 44px;
        margin-left: -54px;
      }

      input:focus {
        outline: 1px solid orange;
      }
    }
  }

  .taskstats {
    display: grid;
    grid-template-columns: 2.5fr 1fr;
    gap: 1em;
    margin-top: 1em;
    margin-bottom: 1em;

    .bar {
      display: grid;
      grid-template-columns: 10px 1fr;
      background-color: white;
      box-shadow: 1px 1px 1px $primary;

      div {
        width: 100%;
        height: 100%;
        background-color: $accent;
      }

      h2 {
        font-weight: 600;
        padding: 8px;
        font-size: medium;
        color: $primary;
      }
    }

    .section {
      width: 100%;
      height: 100%;
      background-color: white;
      box-shadow: 1px 1px 3px #60606030;
      padding: 10px;

      h3 {
        text-align: center;
        margin-bottom: 10px;
        font-size: medium;
        font-weight: 500;
        color: gray;
      }

      .gauge {
        display: grid;
        grid-template-rows: auto 1fr;
        height: 100%;

        .gg {
          display: block;
          margin-top: auto;
        }
      }

      .single {
        display: grid;
        grid-template-columns: auto 1fr;
        place-content: center;
        gap: 10px;
        margin: auto;

        .ts {
          width: 44px;
          height: 44px;
          color: $accent;
          display: block;
          margin: auto;
        }

        h4 {
          font-size: xx-large;
          font-weight: 900;
          color: gray;
          text-align: right;
          margin-bottom: 8px;
        }

        p {
          font-size: small;
          color: gray;
          text-align: right;
        }
      }

      .perf {
        display: grid;
        grid-template-rows: 1fr auto auto auto;
        gap: 10px;
        height: 100%;
        width: 100%;

        h2 {
          font-size: medium;
          font-weight: 520;
        }

        .hl {
          margin: auto;

          h4 {
            color: red;
            font-size: xx-large;
            font-weight: bolder;
            margin-bottom: 10px;
            text-align: center;
          }

          h5 {
            font-weight: 500;
            color: gray;
            text-align: center;
          }
        }

        .div1auto {
          width: 100%;
          display: grid;
          grid-template-columns: 10px 1fr auto;
          gap: 10px;
          padding: 8px;

          div {
            width: 100%;
            height: 100%;
          }

          p {
            font-size: medium;
          }

          h6 {
            font-size: large;
            font-weight: bolder;
            color: gray;
          }
        }
      }
    }

    .left {
      display: grid;
      grid-template-rows: auto 1fr;
      gap: 1em;

      .outer {
        display: grid;
        grid-template-columns: 1fr 2fr;
        gap: 1em;

        .ll {
          display: grid;
          grid-template-rows: auto 1fr;
          gap: 1em;
        }
      }
    }

    .right {
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-rows: auto 1fr;
      gap: 1em;

      .outer {
        height: 100%;
      }
    }
  }

  .intro {
    padding: 1em;

    h4 {
      margin-top: 10px;
      font-size: x-large;
      font-weight: 600;

      span {
        color: orange;
        margin-right: 10px;
      }
    }
  }

  .div3equal {
    gap: 2em !important;
  }

  .litem {
    h3 {
      font-size: large;
      font-weight: 500;
      margin: 1em 0 1em 0;
      padding-left: 10px;
    }

    .content {
      .accordion-body {
        padding: 10px;
        background-color: white;
        border-radius: 8px;
        border: 1px solid #60606010;
        box-shadow: 2px 4px 30px #60606030;
        margin-bottom: 1em;
        display: grid;
        grid-template-columns: minmax(50px, 150px) 1fr 0.3fr 44px;
        gap: 1em;

        .date {
          padding: 10px;
          border-radius: 5px;
          text-align: center;
          color: white;
          background-color: $primary;
          display: grid;
          place-content: center;

          h1 {
            font-size: x-large;
          }

          p {
            font-size: medium;
          }
        }

        .details {
          h4 {
            font-size: medium;
            font-weight: 560;
            margin-bottom: 5px;
          }

          p {
            font-size: small;
            color: gray;
          }
        }

        .deadline {
          display: grid;
          place-content: center;

          h4 {
            color: $accent;
            font-size: large;
            text-align: center;
          }
        }

        .progress {
          position: relative;
          display: flex;
          align-items: center;
          background-color: gray;
          border-radius: 5px;
          height: 100%;
          width: 100%;
          overflow: hidden;

          h4 {
            padding: 1em;
            color: white;
            border-radius: 44px;
            text-align: center;
            display: block;
            margin: auto;
          }

          p {
            color: white;
            text-align: center;
            width: 100%;
            font-size: small;
            z-index: 99;
          }

          .prg {
            position: absolute;
            top: auto;
            left: 0;
            bottom: 0;
            right: 0;
            height: 0%;
            width: 100%;
            background-color: $primary;
          }
        }
      }

      .accordion-body:hover {
        box-shadow: 2px 2px 5px $primary;
      }
    }
  }

  .selector {
    background-color: white;
    // margin-left: 1em;
    // margin-right: 1em;

    .cont {
      display: grid;
      grid-template-columns: repeat(3, auto);
      gap: 3em;
      width: fit-content;

      .item {
        display: grid;
        grid-template-columns: 10px 1fr;

        div {
          width: 100%;
          height: 100%;
        }
      }

      h3 {
        cursor: pointer;
        font-weight: 560;
        width: fit-content;
        font-size: medium;
        padding: 8px;
      }

      .item:hover {
        h3 {
          color: $primary;
        }

        div {
          background-color: $accent;
        }
      }

      .active {
        display: grid;
        grid-template-columns: 10px 1fr;

        div {
          width: 100%;
          height: 100%;
        }

        h3 {
          color: $primary;
        }

        div {
          background-color: $accent;
        }
      }
    }
  }

  .tcontent {
    margin-top: 1em;

    .accordion-body {
      padding: 10px;
      background-color: white;
      border-radius: 8px;
      border: 1px solid #60606010;
      box-shadow: 2px 4px 30px #60606030;
      margin-bottom: 1em;
      display: grid;
      grid-template-columns: minmax(50px, 150px) 1fr 0.5fr 44px;
      gap: 1em;

      .date {
        padding: 10px;
        border-radius: 5px;
        text-align: center;
        color: white;
        background-color: $primary;
        display: grid;
        place-content: center;

        h1 {
          font-size: x-large;
        }

        p {
          font-size: medium;
        }
      }

      .details {
        h4 {
          font-size: medium;
          font-weight: 560;
          margin-bottom: 5px;
        }

        p {
          font-size: small;
          color: gray;
        }
      }

      .deadline {
        display: grid;
        place-content: center;

        h4 {
          color: $accent;
          font-size: large;
          text-align: center;
          margin-bottom: 10px;
        }
      }

      .progress {
        position: relative;
        display: flex;
        align-items: center;
        background-color: gray;
        border-radius: 5px;
        height: 100%;
        width: 100%;
        overflow: hidden;

        h4 {
          padding: 1em;
          color: white;
          border-radius: 44px;
          text-align: center;
          display: block;
          margin: auto;
        }

        p {
          color: white;
          text-align: center;
          width: 100%;
          font-size: small;
          z-index: 99;
        }

        .prg {
          position: absolute;
          top: auto;
          left: 0;
          bottom: 0;
          right: 0;
          height: 0%;
          width: 100%;
          background-color: $primary;
        }
      }
    }
  }

  .list {
    background-color: white;
    padding: 1em;
    border-radius: 5px;
    position: relative;

    h3 {
      padding: 10px 0 10px 0;
      font-size: medium;
    }

    .bar {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1px;

      p {
        padding: 5px;
        background-color: $primary;
        cursor: pointer;
        font-weight: 500;
        color: white;
        border-radius: 2px;
        border-bottom: 2px solid $primary;
      }

      .active {
        background-color: white;
        color: $primary !important;
      }
    }

    .new {
      padding: 1em 0;
      .utp {
        display: grid;
        grid-template-columns: 1fr auto auto;
        gap: 1em;

        .fa-add {
          height: 32px;
          width: 32px;
          color: white;
          background-color: $secondary;
          cursor: pointer;
          padding: 5px;
          border-radius: 5px;
          cursor: pointer;
          margin: auto;
          display: block;
        }
        .fa-add:hover {
          background-color: $primary;
        }

        h3 {
          color: $secondary;
          font-size: medium;
          font-weight: 520;
          display: flex;
          align-items: center;
        }

        p {
          font-size: small;
          color: white;
          background-color: $secondary;
          border-radius: 5px;
          border: 1px solid $secondary;
          cursor: pointer;
          display: flex;
          align-items: center;
          padding: 5px 10px;

          .fa-add {
            margin-right: 5px;
          }
        }

        p:hover {
          color: $secondary;
          background-color: white;
        }

        .search {
          position: relative;

          .fa-search {
            position: absolute;
            top: 0;
            right: 5px;
            bottom: 0;
            height: 100%;
            display: flex;
            align-items: center;
            color: #60606050;
          }

          input {
            width: 100%;
            padding: 10px;
            outline: none;
            border: 1px solid #60606050;
            font-size: small;
            border-radius: 5px;
          }
        }
      }

      .selected {
        .item {
          padding: 10px;
          box-shadow: 1px 1px 5px #60606030;
          border-radius: 5px;
          position: relative;

          h3 {
            font-size: smaller;
            border-bottom: 1px solid #60606030;
            padding-bottom: 5px;
            font-weight: 520;
          }

          h4 {
            font-size: small;
            padding-bottom: 5px;
            font-weight: 520;
            margin-top: 5px;
          }

          .ic {
            color: orange;
            position: absolute;
            right: 10px;
            top: 10px;
            cursor: pointer;
          }
        }
      }

      .customer-box {
        padding: 0;
        border: 1px solid #60606010;
        box-shadow: 1px 1px 5px #60606030;
        border-radius: 5px;
        display: grid;
        grid-template-columns: auto 1fr;
        height: 100%;
        gap: 10px;
        cursor: pointer;
        margin-top: 10px;

        .left {
          display: grid;
          place-content: center;

          h2 {
            background-color: $secondary;
            border-radius: 5px;
            font-size: small;
            font-weight: 600;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            align-self: center;
            min-width: 100px;
            color: white;
            text-align: center;
            padding: 10px;

            * {
              margin: auto;
            }
          }

          h4 {
            font-size: small;
            font-weight: 400;
            color: $primary;
            text-align: center;
          }
        }

        .right {
          padding: 5px;

          h4 {
            font-size: small;
            font-weight: 520;
          }

          p {
            font-size: small;
          }
        }
      }

      .customer-box:hover {
        box-shadow: 1px 1px 5px $primarylight;
      }

      h3 {
        font-size: medium;
        font-weight: 500;
      }

      h6 {
        font-size: small;
        font-weight: 400;
        color: orange;
        text-align: center;
        height: 12px;
        line-height: 12px;
        margin-bottom: 8px;
      }

      hr {
        color: #60606010;
      }

      .filelist {
        .file {
          display: grid;
          grid-template-columns: auto auto;
          gap: 1em;
          width: fit-content;
          box-shadow: 1px 1px 2px #60606030;
          border: 1px solid #60606030;
          border-radius: 3px;
          padding: 5px;
          margin-bottom: 3px;
          cursor: pointer;

          i {
            display: block;
            margin: auto;
            text-align: center;
          }
        }

        .file:hover {
          i {
            color: red;
          }
        }
      }

      .search {
        position: relative;

        .search_list {
          position: absolute;
          left: 0;
          right: 0;
          top: 74px;
          padding: 10px;
          border: 1px solid #60606030;
          border-radius: 5px;
          box-shadow: 1px 1px 5px #60606030;

          p {
            font-size: small;
            padding: 5px;
            cursor: pointer;
            margin-bottom: 3px;
          }

          p:hover {
            color: $secondary;
          }
        }
      }

      .item {
        box-shadow: 1px 1px 5px #60606030;
        border-radius: 5px;
        width: 100%;
        position: relative;
        padding: 10px;
        margin-top: 34px;

        h3,
        p {
          font-size: small;
        }

        .fa-times {
          position: absolute;
          right: 10px;
          top: 10px;
        }
      }

      button {
        display: block;
        margin: 1em auto 1em auto;
        padding: 8px;
        width: 80%;
        background-color: $primary;
        color: white;
        border-right: none;
        border-left: none;
        border-bottom: 3px solid rgba($primary, 0.5);
        border-top: 2px solid #60606070;
        cursor: pointer;
      }

      button:hover {
        background-color: rgba($color: $primary, $alpha: 0.8);
      }

      .checks {
        h4 {
          display: block;
          margin: 0px 0 10px 0;
          font-weight: 400;
        }

        .s_list {
          box-shadow: 1px 1px 3px #60606010;
          border: 1px solid #60606010;
          border-radius: 2px;
          margin: 6px 0 6px 0;
          display: grid;
          grid-template-columns: 1fr auto;
          gap: 10px;

          .fa-times {
            display: block;
            padding: 6px;
            margin: auto;
            cursor: pointer;
            color: $primary;
          }

          .fa-times:hover {
            color: $accent;
          }

          p {
            font-size: small;
            padding: 10px;
          }
        }

        .dropdown {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 99;
          display: flex;
          align-items: center;
          align-content: center;
          background-color: rgba($color: #000000, $alpha: 0.05);

          .content {
            width: fit-content;
            margin: auto;
            padding: 1em;
            background-color: white;
            box-shadow: 1px 1px 3px #60606010;
            border: 1px solid #60606010;
            border-radius: 5px;
            position: relative;

            .fa-times {
              color: $accent;
              position: absolute;
              top: 1em;
              right: 1em;
              cursor: pointer;
            }
          }

          .dlist {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 10px;

            .t_list {
              display: grid;
              grid-template-columns: 1fr auto;
              gap: 10px;
              cursor: pointer;
              box-shadow: 1px 1px 3px #60606010;

              .fa-plus {
                display: block;
                padding: 6px;
                margin: auto;

                color: $primary;
              }

              p {
                font-size: small;
                padding: 4px;
              }
            }

            .t_list:hover {
              background-color: $bg;
            }
          }
        }

        .add {
          margin: 1em auto 10px auto;
          padding: 5px 2em 5px 2em;
          background-color: $primary;
          border-radius: 5px;
          color: white;
          cursor: pointer;
        }

        .add:hover {
          background-color: $accent;
        }
      }

      .div2equal {
        gap: 3em !important;
      }

      .u_list {
        box-shadow: 1px 1px 3px #60606010;
        border: 1px solid #60606010;
        border-radius: 2px;
        margin: 6px 0 6px 0;
        padding: 10px;
        position: relative;

        .div2equal {
          gap: 1em !important;
        }

        .fa-times {
          position: absolute;
          right: 10px;
          top: 10px;
          color: $accent;
          cursor: pointer;
        }

        h4 {
          font-size: medium;
          font-weight: 500;
          text-align: center;
          color: $primary;
        }

        b {
          font-weight: 500;
        }

        p {
          font-size: small;
          font-weight: 200;
          padding: 3px;
        }
      }

      //new user popup
      .popup {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        z-index: 9999;
        background-color: rgba($color: #000000, $alpha: 0.2);

        .wrap {
          max-width: 70vw;
          width: 100%;
          margin: auto;
          background-color: white;
          border-radius: 5px;
          box-shadow: 5px #60606050;
          padding: 10px 1em;
          position: relative;

          .loading {
            position: fixed !important;
            background-color: transparent !important;
          }

          .div21 {
            display: grid;
            grid-template-columns: 2fr 1fr;
            gap: 1em;
          }

          hr {
            color: #60606010;
            margin: 10px 0 10px 0;
          }

          h5 {
            color: orange;
            text-align: center;
            font-size: small;
            font-weight: 400;
            height: 16px;
            line-height: 16px;
          }

          .head {
            display: grid;
            grid-template-columns: 1fr auto;
            gap: 1em;

            h3 {
              font-size: medium;
              font-weight: 520;
              color: $primary;
            }

            .fa-times {
              color: orange;
              cursor: pointer;
            }

            .fa-times:hover {
              color: orangered;
            }
          }

          .button {
            background-color: $primary;
            border: none;
            border-radius: 5px;
            padding: 10px 2em;
            margin: auto;
            display: block;
            cursor: pointer;
            color: white;
            box-shadow: 1px 1px #60606030;
          }

          .button:hover {
            background-color: $primary;
          }

          .input {
            margin: 0 0 10px 0;

            .pwd {
              position: relative;
              margin: 0px 0 0 0;

              .fa-eye {
                position: absolute;
                top: 5px;
                bottom: 0;
                height: 100%;
                right: 5px;
                color: $secondary;
                cursor: pointer;
                display: flex;
                align-items: center;
              }
            }

            label {
              margin-bottom: 10px;
              font-size: medium;
            }

            input {
              padding: 7px;
              width: 100%;
              border-top: none;
              border-right: none;
              border-left: none;
              outline: none;
              border: 1px solid #60606010;
              border-radius: 5px;
              font-size: small;
              margin-top: 10px;
              background-color: $bg1;
            }

            input:focus {
              border-bottom: 2px solid $primary;
            }
          }

          .select {
            margin: 0 0 10px 0;

            label {
              margin-bottom: 10px;
              font-size: small;
            }

            select {
              padding: 7px;
              width: 100%;
              border-top: none;
              border-right: none;
              border-left: none;
              outline: none;
              border: 1px solid #60606010;
              border-radius: 5px;
              font-size: small;
              margin-top: 10px;
              background-color: $bg1;
            }

            select:focus {
              border-bottom: 2px solid $primary;
            }
          }
        }
      }
    }
  }

  .request_popup {
    .pcontainer {
      background-color: white;
      border-radius: 4px;
      margin: 0 auto 0 auto;
      color: #000000;
      height: 100%;
      overflow-y: auto;
      width: 100%;
      position: relative;

      h5 {
        color: $primary;
        cursor: pointer;
        font-size: small !important;
      }

      .tbar {
        background-color: $primary;
        padding: 10px;
        display: grid;
        grid-template-columns: 1fr auto;
        gap: 10px;

        h3 {
          font-size: smaller;
          color: white;
        }

        p {
          font-size: smaller;
          color: white;
        }

        .fa {
          color: white;
          height: fit-content;
          margin: auto;
          padding: 5px;
        }

        .fa:hover {
          color: $accent;
        }
      }

      .wcontent {
        padding: 1em;
        position: relative;

        h6 {
          text-align: center;
          color: $accent;
          margin-top: 5px;
          font-weight: 500;
        }

        .u_list {
          box-shadow: 1px 1px 3px #60606010;
          border: 1px solid #60606010;
          border-radius: 2px;
          margin: 6px 0 6px 0;
          padding: 10px;
          position: relative;
          cursor: pointer;

          .updateprp {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            z-index: 999;
            background-color: #60606010;

            .up_wrapper {
              margin: auto;
              background-color: white;
              min-width: 250px;
              width: fit-content;
              padding: 1em;
              box-shadow: 1px 1px 3px #60606010;
              border: 1px solid #60606010;
              border-radius: 5px;

              h6 {
                margin: 5px 0 5px 0;
                min-height: 16px;
                color: $accent;
                text-align: center;
                font-size: small;
                font-weight: 100;
              }

              .usrinput {
                margin: 10px 0 10px 0;

                h4 {
                  display: block;
                  text-align: left;
                  font-weight: 400;
                  font-size: small;
                  color: #000000 !important;
                }

                input {
                  margin-top: 0px !important;
                  padding: 10px;
                  width: 100%;
                  background-color: $bg1;
                  border-top: none;
                  border-right: none;
                  border-left: none;
                  outline: none;
                  border-bottom: 3px solid rgba($primary, 0.5);
                }

                input:focus {
                  border-bottom: 2px solid $primary;
                }
              }
            }
          }

          .p_check {
            box-shadow: 1px 1px 3px #60606010;
            border: 1px solid #60606010;
            border-radius: 2px;
            margin: 6px 0 6px 0;
            padding: 5px;
            position: relative;
            cursor: pointer;
            display: grid;
            grid-template-columns: auto 1fr;
            gap: 10px;

            div {
              width: 24px;
              height: 24px;
              margin: auto !important;
              border-radius: 24px;
            }

            p {
              padding: 0 !important;
            }
          }

          .p_check:hover {
            background-color: #fff5b8;
          }

          hr {
            color: #60606010;
            margin-top: 5px;
          }

          .div2equal {
            gap: 1em !important;
          }

          .no {
            position: absolute;
            right: 5px;
            top: 0;
            bottom: 0;
            height: 100%;
            left: auto;
            display: flex;
            align-items: center;

            p {
              color: white;
              font-size: small;
              font-weight: 600;
              text-align: center;
              padding: 5px 10px;
              border-radius: 24px;
              background-color: $accent;
            }
          }

          .fa-times {
            position: absolute;
            right: 10px;
            top: 10px;
            color: $accent;
            cursor: pointer;
          }

          h3,
          h4 {
            font-size: medium;
            font-weight: 500;
            text-align: center;
            color: $primary;
          }

          b {
            font-weight: 500;
          }

          p {
            font-size: small;
            font-weight: 200;
            padding: 3px;
          }
        }

        .div3equal {
          gap: 10px !important;
        }

        .usrinput {
          margin: 10px 0 10px 0;

          h4 {
            display: block;
            margin-bottom: 10px !important;
            font-weight: 400;
            font-size: small;
          }

          input {
            margin-top: 10px;
            padding: 10px;
            width: 100%;
            background-color: $bg1;
            border-top: none;
            border-right: none;
            border-left: none;
            outline: none;
            border-bottom: 3px solid rgba($primary, 0.5);
          }

          input:focus {
            border-bottom: 2px solid $primary;
          }
        }

        .search {
          position: relative;

          .search_list {
            position: absolute;
            left: 0;
            right: 0;
            top: 74px;
            padding: 5px;
            border: 1px solid #60606030;
            border-radius: 5px;
            box-shadow: 1px 1px 5px #60606030;
            z-index: 999;
            background-color: white;

            p {
              font-size: x-small;
              padding: 5px;
              cursor: pointer;
              margin-bottom: 3px;
            }

            p:hover {
              color: $secondary;
            }
          }
        }

        .selected_staff {
          box-shadow: 1px 1px 5px #60606030;
          border-radius: 5px;
          width: 100%;
          position: relative;
          padding: 10px;
          margin-top: 10px;

          h3,
          p {
            font-size: small;
          }

          .fa-times {
            position: absolute;
            right: 10px;
            top: 10px;
          }
        }

        button {
          display: block;
          margin: 10px auto 10px auto;
          padding: 4px;
          width: 80%;
          font-size: small;
          background-color: $primary;
          color: white;

          border-right: none;
          border-left: none;
          border-bottom: 3px solid rgba($primary, 0.5);
          border-top: 2px solid #60606070;
          cursor: pointer;
        }

        button:hover {
          background-color: rgba($color: $primary, $alpha: 0.8);
        }

        .div {
          box-shadow: 1px 1px 3px #60606010;
          border: 1px solid #60606010;
          border-radius: 2px;
          padding: 10px;
          min-height: 90%;
          height: 100%;
        }

        .prg {
          display: grid;
          grid-template-rows: repeat(5, 1fr);
          gap: 5px;
          height: auto;

          .item {
            display: grid;
            grid-template-columns: 5px 1fr;
            gap: 10px;
            height: 100%;

            p {
              display: block;
              margin: auto;
              width: 100%;
            }

            div {
              height: 100%;
              width: 100%;
              background-color: orange;
            }
          }
        }

        h5 {
          font-size: medium;
          font-weight: 600;
          margin-bottom: 10px;
          line-height: 24px;
          height: 24px;
        }

        h4,
        p {
          margin: 4px 0 4px 0;
          font-size: small;
        }

        h4 {
          font-weight: 600;
        }

        .link {
          color: $primary;
          text-transform: capitalize;
          cursor: pointer;
        }

        .link:hover {
          color: $secondary;
        }
      }
    }
  }

  .subjects {
    background-color: white;
    border-radius: 4px;
    margin: 1em auto 1em auto;
    color: #000000;
    height: 100%;
    overflow-y: auto;
    width: 100%;
    position: relative;

    .wcontent {
      padding: 1em;
      position: relative;

      .div2equal {
        grid-template-columns: 1fr 2fr !important;
      }

      h6 {
        text-align: center;
        color: $accent;
        margin-top: 5px;
        font-weight: 500;
      }

      .u_list {
        box-shadow: 1px 1px 3px #60606010;
        border: 1px solid #60606010;
        border-radius: 2px;
        margin: 6px 0 6px 0;
        padding: 10px;
        position: relative;
        cursor: pointer;

        .updateprp {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          z-index: 999;
          background-color: #60606010;

          .up_wrapper {
            margin: auto;
            background-color: white;
            min-width: 250px;
            width: fit-content;
            padding: 1em;
            box-shadow: 1px 1px 3px #60606010;
            border: 1px solid #60606010;
            border-radius: 5px;

            h6 {
              margin: 5px 0 5px 0;
              min-height: 16px;
              color: $accent;
              text-align: center;
              font-size: small;
              font-weight: 100;
            }

            .usrinput {
              margin: 10px 0 10px 0;

              h4 {
                display: block;
                text-align: left;
                font-weight: 400;
                font-size: small;
                color: #000000 !important;
              }

              input {
                margin-top: 0px !important;
                padding: 10px;
                width: 100%;
                background-color: $bg1;
                border-top: none;
                border-right: none;
                border-left: none;
                outline: none;
                border-bottom: 3px solid rgba($primary, 0.5);
              }

              input:focus {
                border-bottom: 2px solid $primary;
              }
            }
          }
        }

        .p_check {
          box-shadow: 1px 1px 3px #60606010;
          border: 1px solid #60606010;
          border-radius: 2px;
          margin: 6px 0 6px 0;
          padding: 5px;
          position: relative;
          cursor: pointer;
          display: grid;
          grid-template-columns: auto 1fr;
          gap: 10px;

          div {
            width: 24px;
            height: 24px;
            margin: auto !important;
            background-color: red;
            border-radius: 24px;
          }

          p {
            padding: 0 !important;
          }
        }

        .p_check:hover {
          background-color: #fff5b8;
        }

        hr {
          color: #60606010;
          margin-top: 5px;
        }

        .div2equal {
          gap: 1em !important;
        }

        .no {
          position: absolute;
          right: 5px;
          top: 0;
          bottom: 0;
          height: 100%;
          left: auto;
          display: flex;
          align-items: center;

          p {
            color: white;
            font-size: small;
            font-weight: 600;
            text-align: center;
            padding: 5px 10px;
            border-radius: 24px;
            background-color: $accent;
          }
        }

        .fa-times {
          position: absolute;
          right: 10px;
          top: 10px;
          color: $accent;
          cursor: pointer;
        }

        h3,
        h4 {
          font-size: medium;
          font-weight: 500;
          text-align: center;
          color: $primary;
        }

        b {
          font-weight: 500;
        }

        p {
          font-size: small;
          font-weight: 200;
          padding: 3px;
        }
      }

      .div3equal {
        gap: 10px !important;
      }

      .usrinput {
        margin: 10px 0 10px 0;

        h4 {
          display: block;
          margin-bottom: 10px !important;
          font-weight: 400;
          font-size: small;
        }

        input {
          margin-top: 10px;
          padding: 10px;
          width: 100%;
          background-color: $bg1;
          border-top: none;
          border-right: none;
          border-left: none;
          outline: none;
          border-bottom: 3px solid rgba($primary, 0.5);
        }

        input:focus {
          border-bottom: 2px solid $primary;
        }
      }

      .search {
        position: relative;

        .search_list {
          position: absolute;
          left: 0;
          right: 0;
          top: 74px;
          padding: 5px;
          border: 1px solid #60606030;
          border-radius: 5px;
          box-shadow: 1px 1px 5px #60606030;
          z-index: 999;
          background-color: white;

          p {
            font-size: x-small;
            padding: 5px;
            cursor: pointer;
            margin-bottom: 3px;
          }

          p:hover {
            color: $secondary;
          }
        }
      }

      .selected_staff {
        box-shadow: 1px 1px 5px #60606030;
        border-radius: 5px;
        width: 100%;
        position: relative;
        padding: 10px;
        margin-top: 10px;

        h3,
        p {
          font-size: small;
        }

        .fa-times {
          position: absolute;
          right: 10px;
          top: 10px;
        }
      }

      button {
        display: block;
        margin: 10px auto 10px auto;
        padding: 4px;
        width: 80%;
        font-size: small;
        background-color: $primary;
        color: white;
        border-right: none;
        border-left: none;
        border-bottom: 3px solid rgba($primary, 0.5);
        border-top: 2px solid #60606070;
        cursor: pointer;
      }

      button:hover {
        background-color: rgba($color: $primary, $alpha: 0.8);
      }

      .div {
        box-shadow: 1px 1px 3px #60606010;
        border: 1px solid #60606010;
        border-radius: 2px;
        padding: 10px;
        min-height: 90%;
        height: 100%;
      }

      .prg {
        display: grid;
        grid-template-rows: repeat(5, 1fr);
        gap: 5px;
        height: auto;

        .item {
          display: grid;
          grid-template-columns: 5px 1fr;
          gap: 10px;
          height: 100%;

          p {
            display: block;
            margin: auto;
            width: 100%;
          }

          div {
            height: 100%;
            width: 100%;
            background-color: orange;
          }
        }
      }

      h5 {
        font-size: medium;
        font-weight: 600;
        margin-bottom: 10px;
        line-height: 24px;
        height: 24px;
      }

      h4,
      p {
        margin: 4px 0 4px 0;
        font-size: small;
      }

      h4 {
        font-weight: 600;
      }

      .link {
        color: $primary;
        text-transform: capitalize;
        cursor: pointer;
      }

      .link:hover {
        color: $secondary;
      }
    }
  }

  .popup {
    .pcontainer {
      background-color: white;
      border-radius: 4px;
      margin: 0 auto 0 auto;
      color: #000000;
      width: 100%;
      position: relative;

      h5 {
        color: $primary;
        cursor: pointer;
        font-size: small !important;
      }

      .tbar {
        background-color: $primary;
        padding: 16px !important;

        h3 {
          text-align: center;
          font-size: medium;
          color: white;
        }

        .fa {
          position: absolute;
          top: 10px;
          right: 10px;
          color: white;
          height: fit-content;
          margin: auto;
          padding: 5px;
        }

        .fa:hover {
          color: $accent;
        }
      }

      .wcontent {
        padding: 1em;
        position: relative;
        margin-top: 1em;

        h6 {
          text-align: center;
          color: $accent;
          margin-top: 5px;
          font-weight: 500;
        }

        .u_list {
          box-shadow: 1px 1px 3px #60606010;
          border: 1px solid #60606010;
          border-radius: 2px;
          margin: 6px 0 6px 0;
          padding: 10px;
          position: relative;
          cursor: pointer;

          .updateprp {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            z-index: 999;
            background-color: #60606010;

            .up_wrapper {
              margin: auto;
              background-color: white;
              min-width: 250px;
              width: fit-content;
              padding: 1em;
              box-shadow: 1px 1px 3px #60606010;
              border: 1px solid #60606010;
              border-radius: 5px;

              h6 {
                margin: 5px 0 5px 0;
                min-height: 16px;
                color: $accent;
                text-align: center;
                font-size: small;
                font-weight: 100;
              }

              .usrinput {
                margin: 10px 0 10px 0;

                h4 {
                  display: block;
                  text-align: left;
                  font-weight: 400;
                  font-size: small;
                  color: #000000 !important;
                }

                input {
                  margin-top: 0px !important;
                  padding: 10px;
                  width: 100%;
                  background-color: $bg1;
                  border-top: none;
                  border-right: none;
                  border-left: none;
                  outline: none;
                  border-bottom: 3px solid rgba($primary, 0.5);
                }

                input:focus {
                  border-bottom: 2px solid $primary;
                }
              }
            }
          }

          .p_check {
            box-shadow: 1px 1px 3px #60606010;
            border: 1px solid #60606010;
            border-radius: 2px;
            margin: 6px 0 6px 0;
            padding: 5px;
            position: relative;
            cursor: pointer;
            display: grid;
            grid-template-columns: auto 1fr;
            gap: 10px;

            div {
              width: 24px;
              height: 24px;
              margin: auto !important;
              background-color: red;
              border-radius: 24px;
            }

            p {
              padding: 0 !important;
            }
          }

          .p_check:hover {
            background-color: #fff5b8;
          }

          hr {
            color: #60606010;
            margin-top: 5px;
          }

          .div2equal {
            gap: 1em !important;
          }

          .no {
            position: absolute;
            right: 5px;
            top: 0;
            bottom: 0;
            height: 100%;
            left: auto;
            display: flex;
            align-items: center;

            p {
              color: white;
              font-size: small;
              font-weight: 600;
              text-align: center;
              padding: 5px 10px;
              border-radius: 24px;
              background-color: $accent;
            }
          }

          .fa-times {
            position: absolute;
            right: 10px;
            top: 10px;
            color: $accent;
            cursor: pointer;
          }

          h3,
          h4 {
            font-size: medium;
            font-weight: 500;
            text-align: center;
            color: $primary;
          }

          b {
            font-weight: 500;
          }

          p {
            font-size: small;
            font-weight: 200;
            padding: 3px;
          }
        }

        .div3equal {
          gap: 10px !important;
        }

        .usrinput {
          margin: 10px 0 10px 0;

          h4 {
            display: block;
            margin-bottom: 10px !important;
            font-weight: 400;
            font-size: small;
          }

          input {
            margin-top: 10px;
            padding: 10px;
            width: 100%;
            background-color: $bg1;
            border-top: none;
            border-right: none;
            border-left: none;
            outline: none;
            border-bottom: 3px solid rgba($primary, 0.5);
          }

          input:focus {
            border-bottom: 2px solid $primary;
          }
        }

        .search {
          position: relative;

          .search_list {
            position: absolute;
            left: 0;
            right: 0;
            top: 74px;
            padding: 5px;
            border: 1px solid #60606030;
            border-radius: 5px;
            box-shadow: 1px 1px 5px #60606030;
            z-index: 999;
            background-color: white;

            p {
              font-size: x-small;
              padding: 5px;
              cursor: pointer;
              margin-bottom: 3px;
            }

            p:hover {
              color: $secondary;
            }
          }
        }

        .selected_staff {
          box-shadow: 1px 1px 5px #60606030;
          border-radius: 5px;
          width: 100%;
          position: relative;
          padding: 10px;
          margin-top: 10px;

          h3,
          p {
            font-size: small;
          }

          .fa-times {
            position: absolute;
            right: 10px;
            top: 10px;
          }
        }

        button {
          display: block;
          margin: 10px auto 10px auto;
          padding: 4px;
          width: 80%;
          font-size: small;
          background-color: $primary;
          color: white;
          border-right: none;
          border-left: none;
          border-bottom: 3px solid rgba($primary, 0.5);
          border-top: 2px solid #60606070;
          cursor: pointer;
        }

        button:hover {
          background-color: rgba($color: $primary, $alpha: 0.8);
        }

        .div {
          box-shadow: 1px 1px 3px #60606010;
          border: 1px solid #60606010;
          border-radius: 2px;
          padding: 10px;
          min-height: 90%;
          height: 100%;
        }

        .prg {
          display: grid;
          grid-template-rows: repeat(5, 1fr);
          gap: 5px;
          height: auto;

          .item {
            display: grid;
            grid-template-columns: 5px 1fr;
            gap: 10px;
            height: 100%;

            p {
              display: block;
              margin: auto;
              width: 100%;
            }

            div {
              height: 100%;
              width: 100%;
              background-color: orange;
            }
          }
        }

        h5 {
          font-size: medium;
          font-weight: 600;
          margin-bottom: 10px;
          line-height: 24px;
          height: 24px;
        }

        h4,
        p {
          margin: 4px 0 4px 0;
          font-size: small;
        }

        h4 {
          font-weight: 600;
        }

        .link {
          color: $primary;
          text-transform: capitalize;
          cursor: pointer;
        }

        .link:hover {
          color: $secondary;
        }
      }
    }
  }

  @include maxwidth(mobile) {
    padding: 10px !important;

    .welcome {
      grid-template-columns: 1fr !important;

      h1 {
        font-size: medium;
      }
    }

    .selector {
      h3 {
        font-size: small !important;
      }
    }

    .search {
      display: flex;
      grid-template-columns: 1fr !important;

      input {
        display: inline-block !important;
      }

      .fa {
        display: none !important;
      }
    }

    .accordion-body {
      grid-template-columns: 1fr !important;
      gap: 0 !important;

      .status {
        grid-template-columns: 1fr !important;
      }

      h4 {
        font-size: small !important;
        margin-top: 8px;
      }

      .date {
        display: flex;
        gap: 10px;

        p {
          font-size: medium !important;
          margin: auto !important;
          display: block;
        }
      }

      .list {
        grid-template-columns: 1fr !important;
        gap: 0 !important;
        padding: 0 !important;

        p {
          padding: 0 !important;
          margin-bottom: 3px;
        }
      }
    }

    .popup {
      .div3equal {
        .div {
          margin-bottom: 10px !important;
          min-height: auto !important;
        }
      }

      .pcontainer {
        max-width: 85vw !important;
        width: 100% !important;
        height: 100% !important;
        overflow-y: auto;

        .link {
          word-break: break-all !important;
        }
      }
    }

    h2,
    h3,
    p,
    h4,
    h5,
    label,
    h6 {
      font-size: small !important;
    }

    .div3equal,
    .div2equal {
      grid-template-columns: 1fr !important;
    }
  }

  @include maxwidth(tablet) {
    padding: 10px !important;

    .welcome {
      grid-template-columns: 1fr !important;

      h1 {
        font-size: medium;
      }
    }

    .selector {
      h3 {
        font-size: small !important;
      }
    }

    .search {
      display: flex;
      grid-template-columns: 1fr !important;

      input {
        display: inline-block !important;
      }

      .fa {
        display: none !important;
      }
    }

    .accordion-body {
      grid-template-columns: 1fr !important;
      gap: 0 !important;

      .status {
        grid-template-columns: 1fr !important;
      }

      h4 {
        font-size: small !important;
        margin-top: 8px;
      }

      .date {
        display: flex;
        gap: 10px;

        p {
          font-size: medium !important;
          margin: auto !important;
          display: block;
        }
      }

      .list {
        grid-template-columns: 1fr !important;
        gap: 0 !important;
        padding: 0 !important;

        p {
          padding: 0 !important;
          margin-bottom: 3px;
        }
      }
    }

    .popup {
      .div3equal {
        .div {
          margin-bottom: 10px !important;
          min-height: auto !important;
        }
      }

      .pcontainer {
        max-width: 85vw !important;
        width: 100% !important;
        height: 100% !important;
        overflow-y: auto;

        .link {
          word-break: break-all !important;
        }
      }
    }

    h2,
    h3,
    p,
    h4,
    h5,
    label,
    h6 {
      font-size: small !important;
    }

    .div3equal,
    .div2equal {
      grid-template-columns: 1fr !important;
    }
  }
}
