@import "./mediaquery";
@import "./variables";

.invoicing {
  height: fit-content !important;
  display: grid;
  grid-template-rows: auto 1fr auto;

  .welcome {
    border-bottom: 1px solid #60606030;

    display: grid;
    grid-template-columns: 1fr auto;
    gap: 1em;

    .refresh {
      width: 32px;
      height: 32px;
      color: $accent;
      cursor: pointer;
      padding: 5px;
      background-color: white;
      border-radius: 5px;
    }

    .refresh:hover {
      color: $primary;
    }

    h1 {
      font-size: large;
      font-weight: 560;
      margin-bottom: 10px;
    }

    .search {
      display: grid;
      grid-template-columns: auto 1fr auto;
      gap: 10px;

      .select {
        select {
          margin: auto;
          background-color: #60606020;
          padding: 1em;
          border: none;
          outline: none;
          border-radius: 44px;
          height: 44px;
        }
      }

      input {
        background-color: #60606020;
        padding: 1em;
        border: none;
        outline: none;
        border-radius: 44px;
        height: 44px;
      }

      i {
        padding: 1em;
        color: orange;
        height: 44px;
        margin-left: -54px;
      }

      input:focus {
        outline: 1px solid orange;
      }
    }
  }

  .taskstats {
    display: grid;
    grid-template-columns: 2.5fr 1fr;
    gap: 1em;
    margin-top: 1em;
    margin-bottom: 1em;

    .bar {
      display: grid;
      grid-template-columns: 10px 1fr;
      background-color: white;
      box-shadow: 1px 1px 1px $primary;

      div {
        width: 100%;
        height: 100%;
        background-color: $accent;
      }

      h2 {
        font-weight: 600;
        padding: 8px;
        font-size: medium;
        color: $primary;
      }
    }

    .section {
      width: 100%;
      height: 100%;
      background-color: white;
      box-shadow: 1px 1px 3px #60606030;
      padding: 10px;

      h3 {
        text-align: center;
        margin-bottom: 10px;
        font-size: medium;
        font-weight: 500;
        color: gray;
      }

      .gauge {
        display: grid;
        grid-template-rows: auto 1fr;
        height: 100%;

        .gg {
          display: block;
          margin-top: auto;
        }
      }

      .single {
        display: grid;
        grid-template-columns: auto 1fr;
        place-content: center;
        gap: 10px;
        margin: auto;
        height: 100%;

        .ts {
          width: 44px;
          height: 44px;
          color: $accent;
          display: block;
          margin: auto;
        }

        h4 {
          font-size: xx-large;
          font-weight: 900;
          color: gray;
          text-align: right;
          margin-bottom: 8px;
        }

        p {
          font-size: small;
          color: gray;
          text-align: right;
        }
      }

      .perf {
        display: grid;
        grid-template-rows: 1fr auto auto auto;
        gap: 10px;
        height: 100%;
        width: 100%;

        h2 {
          font-size: medium;
          font-weight: 520;
        }

        .hl {
          margin: auto;

          h4 {
            color: red;
            font-size: xx-large;
            font-weight: bolder;
            margin-bottom: 10px;
            text-align: center;
          }

          h5 {
            font-weight: 500;
            color: gray;
            text-align: center;
          }
        }

        .div1auto {
          width: 100%;
          display: grid;
          grid-template-columns: 10px 1fr auto;
          gap: 10px;
          padding: 8px;

          div {
            width: 100%;
            height: 100%;
          }

          p {
            font-size: medium;
          }

          h6 {
            font-size: large;
            font-weight: bolder;
            color: gray;
          }
        }
      }
    }

    .left {
      display: grid;
      grid-template-rows: auto 1fr;
      gap: 1em;

      .outer {
        display: grid;
        grid-template-columns: 1fr 2fr;
        gap: 1em;

        .ll {
          display: grid;
          grid-template-rows: 1fr 1.5fr;
          gap: 1em;
        }
      }
    }

    .right {
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-rows: auto 1fr;
      gap: 1em;

      .outer {
        height: 100%;
      }
    }
  }

  .title {
    h3 {
      color: $primary;
      font-size: smaller;
      margin-bottom: 5px;
      font-weight: 520;
    }

    p {
      color: gray;
      font-size: small;
      margin-bottom: 5px;
    }

    hr {
      color: #60606010;
      margin-bottom: 10px;
    }
  }

  .chart {
    height: 100%;
    width: 100%;
    border-radius: 8px;
    position: relative;
    background-color: white;
    box-shadow: 1px 1px 5px #60606030;
    padding: 1em;

    .tp {
      display: flex;
      justify-content: space-between;

      .download {
        height: 16px;
        width: 16px;
        color: $secondary;
        cursor: pointer;
      }

      .download:hover {
        color: $primarylight;
      }

      h3 {
        color: $primary;
        font-size: small;
        margin-bottom: 5px;
        font-weight: 520;
      }

      p {
        color: gray;
        font-size: small;
        margin-bottom: 5px;
      }

      hr {
        color: #60606010;
        margin-bottom: 10px;
      }
    }

    h3 {
      color: gray;
      font-size: medium;
      margin-bottom: 1em;
      font-weight: 600;
    }

    .save {
      position: absolute;
      right: 16px;
      top: 16px;
      display: grid;
      grid-template-columns: auto auto;
      gap: 10px;

      * {
        cursor: pointer;
      }
    }
  }

  .top {
    display: grid;
    grid-template-columns: 2.5fr 2fr;
    gap: 1em;
    margin-bottom: 2em;

    .topcharts {
      padding: 10px;
      background-color: white;
      border-radius: 8px;
      box-shadow: 1px 1px 5px #60606010;
      display: grid;
      grid-template-rows: auto 1fr;

      .topsummary {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 10px;

        .tp_item {
          background-color: white;
          border-radius: 8px;
          background-repeat: no-repeat;
          background-size: contain;
          padding: 1em 10px;
          display: grid;
          grid-template-rows: auto 1fr auto;

          img {
            width: 24px;
            height: 24px;
            object-fit: contain;
          }

          h4 {
            color: $primary;
            font-size: medium;
            font-weight: 520;
            margin: 5px 0 5px 0;
          }

          h3 {
            color: $primarylight;
            align-self: center;
            font-weight: 520;
            font-size: x-large;
            text-align: center;
          }

          h6 {
            margin-top: 4px;
            color: $bg;
            align-self: center;
            font-size: x-small;
            font-weight: 400;
          }
        }
      }
    }

    .topright {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1em;
    }

    .toppies {
      display: grid;
      grid-template-columns: 0.5fr 0.5fr;
      gap: 1.5em;
    }
  }

  .divcharts {
    display: grid;
    grid-template-columns: 3.6fr 1fr;
    gap: 1em;
  }

  .chartrow {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    gap: 1em;
  }

  .pies {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1em;
    margin: 1em 0 1em 0;

    .tp {
      display: flex;
    }
  }

  .subtitle {
    margin: 1em 0 5em 0;
  }

  .financepies {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1em;
    margin: 1em 0 2em 0;
  }

  .middle {
    height: fit-content !important;
    margin: 1em 0 1em 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
  }

  .bottom {
    height: fit-content !important;
    margin: 1em 0 1em 0;
    // display: grid;
    // grid-template-columns: 1fr;
    gap: 1em;
  }

  .list {
    background-color: white;
    padding: 1em;
    border-radius: 0 0 5px 5px;
    position: relative;
    min-height: 50vh;

    .loading {
      position: absolute !important;
      background-color: transparent !important;
    }

    hr {
      color: #60606010;
      margin: 10px 0 10px 0;
    }

    .customer-box {
      padding: 10px;
      border: 1px solid #60606010;
      box-shadow: 1px 1px 5px #60606030;
      border-radius: 5px;
      display: grid;
      grid-template-columns: auto 1fr auto;
      height: fit-content;
      gap: 10px;
      cursor: pointer;
      position: relative;
      margin-bottom: 5px;

      .left {
        display: grid;
        place-content: center;

        h2 {
          background-color: $secondary;
          border-radius: 5px;
          font-size: medium;
          font-weight: 600;
          height: fit-content;
          display: flex;
          align-items: center;
          align-self: center;
          min-width: 100px;
          color: white;
          text-align: center;
          padding: 10px;

          * {
            margin: auto;
          }
        }

        h4 {
          margin-top: 10px;
          font-size: small;
          font-weight: 400;
          color: $primary;
          text-align: center;
        }
      }

      .right {
        h3 {
          margin-bottom: 3px;
          font-size: small;
          font-weight: 520;
        }

        p {
          font-size: small;
          margin-bottom: 2px;
        }

        .calendar {
          font-size: x-small;
          position: absolute !important;
          bottom: 5px;
          right: 5px;
        }

        .status {
          position: absolute;
          top: 10px;
          right: -10px;
          background-color: $primary;
          padding: 5px;
          font-size: x-small;
          color: white;
        }
      }

      .bal {
        padding-right: 54px;
        display: grid;
        place-content: center;
        color: $primary;
      }
    }

    .customer-box:hover {
      box-shadow: 1px 1px 5px $primarylight;
    }

    .utp {
      display: grid;
      grid-template-columns: 1fr auto auto;
      gap: 1em;

      h3 {
        color: $secondary;
        font-size: medium;
        font-weight: 520;
        display: flex;
        align-items: center;
      }

      p {
        font-size: small;
        color: white;
        background-color: $secondary;
        border-radius: 5px;
        border: 1px solid $secondary;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 5px 10px;

        .fa-add {
          margin-right: 5px;
        }
      }

      p:hover {
        color: $secondary;
        background-color: white;
      }

      .search {
        position: relative;

        .fa-search {
          position: absolute;
          top: 0;
          right: 5px;
          bottom: 0;
          height: 100%;
          display: flex;
          align-items: center;
          color: #60606050;
        }

        input {
          width: 100%;
          padding: 10px;
          outline: none;
          border: 1px solid #60606050;
          font-size: small;
          border-radius: 5px;
        }
      }
    }

    .div31 {
      display: grid;
      grid-template-columns: 3fr 1fr;
      gap: 1em;
      min-height: 60vh;

      .lcontainer {
        min-height: 50vh;
      }

      .user-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1em;
        height: fit-content;

        .customer-box {
          padding: 10px;
          border: 1px solid #60606010;
          box-shadow: 1px 1px 5px #60606030;
          border-radius: 5px;
          display: grid;
          grid-template-columns: auto 1fr;
          height: fit-content;
          gap: 10px;
          cursor: pointer;

          .left {
            h2 {
              background-color: $secondary;
              border-radius: 5px;
              font-size: medium;
              font-weight: 600;
              height: 54px;
              display: flex;
              align-items: center;
              align-self: center;
              width: 100px;
              color: white;
              text-align: center;

              * {
                margin: auto;
              }
            }

            h4 {
              margin-top: 10px;
              font-size: small;
              font-weight: 400;
              color: $primary;
              text-align: center;
            }
          }

          .right {
            h3 {
              margin-bottom: 3px;
              font-size: small;
              font-weight: 520;
            }

            p {
              font-size: small;
              margin-bottom: 2px;
            }
          }
        }

        .customer-box:hover {
          box-shadow: 1px 1px 5px $primarylight;
        }

        .user-box {
          padding: 10px;
          border: 1px solid #60606010;
          box-shadow: 1px 1px 5px #60606030;
          border-radius: 5px;
          display: grid;
          grid-template-columns: auto 1fr;
          height: fit-content;
          gap: 10px;
          cursor: pointer;

          .left {
            h2 {
              background-color: $secondary;
              border-radius: 5px;
              font-size: large;
              font-weight: 600;
              height: 54px;
              line-height: 54px;
              width: 54px;
              color: white;
              text-align: center;
            }

            h4 {
              margin-top: 10px;
              font-size: small;
              font-weight: 400;
              color: $primary;
              text-align: center;
            }
          }

          .right {
            h3 {
              margin-bottom: 3px;
              font-size: small;
              font-weight: 520;
            }

            p {
              font-size: small;
              margin-bottom: 2px;
            }
          }
        }

        .user-box:hover {
          box-shadow: 1px 1px 5px $primarylight;
        }

        .active {
          box-shadow: 1px 1px 5px $primarylight;
        }
      }

      .selected {
        padding: 1em;
        border: 1px solid #60606010;
        box-shadow: 1px 1px 5px #60606030;
        border-radius: 5px;
        height: fit-content;

        img {
          border: 1px solid #60606030;
          max-height: 54px;
          height: 100%;
          width: fit-content;
          display: block;
          object-fit: contain;
          margin: auto;
        }

        h4 {
          font-weight: 520;
          font-size: small;
        }

        p {
          font-size: small;
          margin: 8px 0 8px 0;
        }

        .actions {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 10px;
          margin-top: 2em;

          h6 {
            font-size: small;
            padding: 5px;
            background-color: $bg;
            cursor: pointer;
            text-align: center;
            color: $secondary;
            box-shadow: 1px 1px 5px #60606030;
            border-radius: 5px;
            border: 1px solid #60606010;
            font-weight: 400;
          }
        }
      }
    }
  }

  .container {
    border-radius: 8px;
    overflow-x: auto;

    .banner {
      display: grid;
      grid-template-columns: 1fr auto auto;
      gap: 10px;
      background-color: $primarylight;
      padding: 4px;
      place-content: center;

      h4 {
        color: white;
        font-weight: 520;
        display: block;
        margin: auto;
        width: 100%;
      }

      button {
        padding: 5px 10px;
        background-color: transparent;
        border: 1px solid white;
        color: white;
        border-radius: 5px;
        cursor: pointer;
      }

      button:hover {
        background-color: white;
        color: $primarylight;
      }
    }

    .divsplit {
      display: grid;
      grid-template-columns: 2fr 1fr;
      gap: 1em;
      background-color: white;

      .inv_preview {
        background-color: white;
        position: relative;
        padding: 1em;
        max-width: 650px;
        width: 100%;
        overflow-x: auto;
        margin: auto;

        .invoice {
          width: 595px;
          background-color: white;
          height: 100%;
          margin: auto;
          border-radius: 5px;
          box-shadow: 2px 2px 10px #60606050;
          padding-bottom: 74px;

          .bar {
            display: grid;
            grid-template-columns: 3fr auto 54px;
            place-items: center;
            gap: 1em;

            .invtop {
              padding: 1em 2em;
              width: 100%;

              img {
                max-width: 200px;
                width: 100%;
                object-fit: contain;
              }

              h2 {
                font-weight: 600;
                margin-top: 10px;
                font-size: smaller;
              }

              p {
                font-size: smaller;
              }
              h1 {
                font-size: xx-large;
              }
            }

            .div {
              height: 80%;
              width: 100%;
              background-image: linear-gradient(
                $primary 0%,
                $primarylight 30%,
                $primarylighter 50%,
                $primarylight 70%,
                $primary 100%
              );
            }

            h1 {
              font-size: xx-large;
              height: 44px;
              line-height: 44px;
              color: $primary;
            }
          }

          .invheader {
            display: grid;
            grid-template-columns: 2fr 1fr;
            gap: 2em;
            padding: 0 2em 0 2em;

            .left {
              .enclose {
                padding: 10px;
                border: 1px solid #60606050;
                border-radius: 5px;
                height: 100%;

                hr {
                  color: #60606020 !important;
                  margin: 8px 0 8px 0;
                }
              }

              .div1auto {
                display: grid;
                grid-template-columns: 1fr auto;
                width: fit-content;
                gap: 10px;
                margin-bottom: 5px;
              }

              p {
                font-size: smaller;
              }

              h4 {
                font-weight: 510;
                font-size: smaller;
              }
            }

            .right {
              width: 100%;
              height: fit-content;
              margin: auto;

              .div1auto {
                display: grid;
                grid-template-columns: 1fr auto;
                margin-bottom: 5px;
              }

              p {
                font-size: smaller;
              }

              h4 {
                font-weight: 510;
                font-size: smaller;
              }
            }
          }

          .section {
            border: 1px solid #60606030;
            border-radius: 5px;
            margin: 1em 2em 1em 2em;
            min-height: 250px;

            h4 {
              font-weight: 500;
              font-size: small;
              padding: 10px 10px 0 10px;
            }

            .soitem {
              border: 1px solid #60606030;
              padding: 10px;
              margin-bottom: 10px;
            }

            .tphead {
              display: grid;
              grid-template-columns: 1fr 50px 0.3fr 0.3fr;
              background-color: #fdf0f0;

              h3 {
                padding: 5px;
                font-size: small;
                font-weight: 520;
                text-align: left;
                width: 100%;
              }

              :nth-child(2) {
                text-align: center;
              }

              :nth-child(3),
              :nth-child(4) {
                text-align: right;
              }
            }

            .tprow {
              display: grid;
              grid-template-columns: 1fr 50px 0.3fr 0.3fr;
              background-color: white;
              border: 1px solid #60606010;
              border-collapse: collapse;

              h2 {
                padding: 5px;
                font-size: small;
                font-weight: 400;
              }

              :nth-child(2) {
                text-align: center;
              }

              :nth-child(3),
              :nth-child(4) {
                text-align: right;
              }

              .fa-times {
                height: 16px;
                width: 16px;
                align-items: center;
                color: red;
                display: block;
                margin: auto;
                cursor: pointer;
              }

              .fa-times:hover {
                color: orange;
              }
            }

            .item {
              border-bottom: 1px solid #60606010;
              border: 1px solid #60606010;
              padding: 10px;
              margin-bottom: 5px;
              cursor: pointer;

              h2 {
                font-size: small;
                margin-bottom: 2px;
                font-weight: 500;
              }

              h5 {
                font-size: small;
                margin-bottom: 2px;
                color: gray !important;
                font-weight: 200;
              }

              div {
                display: grid;
                grid-template-columns: 1fr auto;
              }

              p {
                font-size: small !important;
              }

              .fa-times {
                margin-left: auto;
                color: $accent;
                display: block;
              }
            }

            .so_list:hover {
              border: 1px solid #60606030;
              background-color: $bg;
            }

            .so_list {
              border-bottom: 1px solid #60606010;
              border: 1px solid #60606010;
              padding: 10px;
              margin-bottom: 5px;
              cursor: pointer;

              h2 {
                font-size: small;
                margin-bottom: 2px;
                font-weight: 500;
              }

              h5 {
                font-size: small;
                margin-bottom: 2px;
                color: gray !important;
                font-weight: 200;
              }
            }

            .search {
              position: relative;

              .search_list {
                position: absolute;
                left: 0;
                right: 0;
                top: 74px;
                padding: 10px;
                border: 1px solid #60606030;
                border-radius: 5px;
                box-shadow: 1px 1px 5px #60606030;
                z-index: 9999;
                background-color: white;

                p {
                  font-size: small;
                  padding: 5px;
                  cursor: pointer;
                  margin-bottom: 3px;
                }

                p:hover {
                  color: $secondary;
                }
              }
            }

            h4 {
              font-size: medium;
              margin-bottom: 10px;
              font-weight: 560;
            }

            hr {
              color: #60606010;
            }

            p {
              font-size: medium;
              margin: 0px 0 5px 0;

              b {
                color: #74465c;
                cursor: pointer;
              }
            }

            .input {
              margin: 10px 0 0 0;

              .pwd {
                position: relative;
                margin: 0px 0 10px 0;

                .fa-eye {
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  right: 5px;
                  color: $secondary;
                  cursor: pointer;
                  display: flex;
                  align-items: center;
                }
              }

              label {
                display: block;
                margin: 10px 0 10px 0;
                font-weight: 400;
                font-size: small;
              }

              textarea {
                margin: 0px 0 10px 0;
                padding: 10px;
                width: 100%;
                background-color: #60606030;
                border-top: none;
                border-right: none;
                border-left: none;
                outline: none;
                border-bottom: 2px solid #60606070;
              }

              textarea:focus {
                border-bottom: 2px solid $primary;
              }

              input {
                padding: 10px;
                width: 100%;
                background-color: #60606030;
                border-top: none;
                border-right: none;
                border-left: none;
                outline: none;
                border-bottom: 2px solid #60606070;
              }

              input:focus {
                border-bottom: 2px solid $primary;
              }
            }

            .select {
              margin: 10px 0 0 0;

              label {
                display: block;
                margin: 10px 0 10px 0;
                font-weight: 400;
                font-size: small;
              }

              select {
                margin: 0px 0 10px 0;
                padding: 10px;
                width: 100%;
                background-color: #60606030;
                border-top: none;
                border-right: none;
                border-left: none;
                outline: none;
                border-bottom: 2px solid #60606070;
              }

              input:focus {
                border-bottom: 2px solid $primary;
              }
            }
          }

          .totals {
            padding: 0 0 0 2em;

            .div1auto {
              display: grid;
              grid-template-columns: 1fr auto;
              gap: 1em;
            }

            .tdiv {
              display: grid;
              grid-template-columns: 1fr auto;
              gap: 10px;
              padding-right: 2em;
            }

            .tt {
              background-image: linear-gradient(
                to right,
                $primary 0%,
                $primarylight 30%,
                $primarylighter 50%,
                $primarylight 70%,
                $primary 100%
              );
              padding: 5px 2em 5px 5px;
              color: white;
              font-weight: 520;
              margin: 5px 0 5px 0;

              * {
                font-size: small !important;
              }
            }

            p,
            h4 {
              margin: 4px 0 4px 0;
            }

            p {
              font-size: x-small;
            }

            h4 {
              font-weight: 520;
              font-size: x-small;
            }
          }

          .payment {
            padding: 0 2em;

            p,
            h4 {
              margin: 4px 0 4px 0;
            }

            p {
              font-size: x-small;
            }

            h4 {
              font-weight: 520;
              font-size: x-small;
            }
          }
        }
        .invfooter {
          margin-top: -74px;
          padding: 1em 0;
          width: 595px;
          margin-left: auto;
          margin-right: auto;

          .fbars {
            display: grid;
            grid-template-columns: 4fr auto 1fr;
            gap: 1em;

            h6 {
              font-weight: 500;
              color: $primary;
            }

            div {
              height: 2px;
              width: 100%;
              background-color: $accent;
              display: block;
              margin: auto;
            }
          }

          .contacts {
            display: grid;
            grid-template-columns: auto auto auto;
            padding: 10px 2em 0 2em;
            width: fit-content;

            p {
              padding-left: 10px;
              padding-right: 10px;
            }

            * {
              font-size: small;
              color: gray;
            }

            :nth-child(2) {
              border-left: 1px solid #60606030;
              border-right: 1px solid #60606030;
            }
          }
        }

        .watermark {
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          background-color: #60606030;
        }
      }

      .iright_panel {
        padding: 1em;

        .links {
          background-color: white;
          border-radius: 5px;
          padding: 1em;
          border: 1px solid #60606010;
          box-shadow: 1px 1px 5px #60606010;

          .ic {
            position: absolute;
            top: 0;
            bottom: 0;
            height: 100%;
            left: 10px;
            margin-right: 1em;
            display: grid;
            place-content: center;
          }

          button {
            padding: 8px 1em;
            border: 1px solid $primary;
            border-radius: 5px;
            cursor: pointer;
            background-color: $primary;
            transition: background-color 0.5s ease-in-out,
              color 0.5s ease-in-out;
            width: 100%;
            color: white;
            margin: 10px 0;
            position: relative;
          }
          button:first-child {
            background-color: $secondary;
            border: 1px solid $secondary;
          }
          button:last-child {
            background-color: $primarylight;
            border: 1px solid $primarylight;
          }
          button:hover {
            background-color: transparent;
            color: $primary;
          }
        }

        .inv_stats {
          background-color: white;
          border-radius: 5px;
          padding: 1em;
          border: 1px solid #60606010;
          box-shadow: 1px 1px 5px #60606010;
          margin: 1em 0;
          height: fit-content;

          .tp {
            display: grid;
            grid-template-columns: 1.4fr 1fr;
            gap: 10px;
            border-bottom: 1px dashed #60606050;

            p {
              font-size: small;
              color: #686868;
            }

            h4 {
              font-size: x-large;
              color: green;
              padding: 10px 0;
            }

            .lf {
              border-right: 1px solid #60606030;
            }

            .rf {
              margin: auto 0 0 auto;

              :first-child {
                font-size: smaller;
                text-align: right;
              }
              :last-child {
                font-size: small;
                text-align: right;
                padding: 5px 0;
                color: $primary;
              }
            }
          }
          .div2equal {
            padding: 8px 0 0 0;

            p {
              color: #686868;
              font-size: smaller;
            }
            h5 {
              font-weight: 400;
              font-size: medium;
              color: red;
              text-align: right;
            }
          }
        }

        .c_payments {
          background-color: white;
          border-radius: 5px;
          padding: 1em;
          border: 1px solid #60606010;
          box-shadow: 1px 1px 5px #60606010;
          margin: 1em 0;
          height: fit-content;

          h3 {
            font-size: medium;
            font-weight: 520;
            color: $primary;
          }

          hr {
            border: 1px solid #60606030;
            margin: 8px 0;
          }

          .pitem {
            display: grid;
            grid-template-columns: auto 1fr auto;
            gap: 10px;
            background-color: white;
            border-radius: 5px;
            padding: 10px;
            border: 1px solid #60606010;
            box-shadow: 1px 1px 5px #60606010;
            margin-bottom: 10px;
            position: relative;

            .fa-times {
              position: absolute;
              top: 2px;
              right: 2px;
              color: $accent;
              cursor: pointer;
              transition: color 0.5s ease-in-out;
            }

            .fa-times:hover {
              color: red;
            }

            .no {
              height: 32px;
              width: 32px;
              border-radius: 50%;
              background-color: antiquewhite;
              border: 1px solid #60606010;
              display: grid;
              place-content: center;
            }

            h4 {
              font-size: smaller;
              font-weight: 500;
            }

            p {
              font-size: small;
              color: #686868;
            }

            h5 {
              display: block;
              margin: auto;
              padding: 4px 10px;
              border-radius: 24px;
              background-color: rgba(0, 128, 0, 0.2);
              color: green;
              font-weight: 400;
              font-size: smaller;
            }
          }
        }
      }
    }

    .topbar {
      display: grid;
      grid-template-columns: 1fr auto;
      gap: 10px;

      .new {
        display: grid;
        grid-template-columns: auto auto;
        gap: 5px;
        font-size: small;
        border-radius: 5px;
        padding: 10px;
        background-color: white;
        width: fit-content;
        height: fit-content;
        cursor: pointer;
      }

      .new:hover {
        background-color: #e5e5e5;
      }

      .top {
        display: grid;
        grid-template-columns: repeat(6, auto);
        gap: 10px;
        width: fit-content;
        margin: 0 0 1em auto;

        .fa,
        .topitem {
          font-size: small;
          border-radius: 5px;
          padding: 10px;
          background-color: white;
          cursor: pointer;
        }

        .fa:hover,
        .topitem:hover {
          background-color: #e5e5e5;
        }

        .active {
          font-size: small;
          border-radius: 5px;
          padding: 10px;
          background-color: #e5e5e5;
        }
      }
    }

    .createworkplan {
      .cpcontainer {
        width: 100%;
        background-color: white;
        overflow-y: auto;
        margin: auto;
        border-radius: 5px;
        box-shadow: 2px 2px 10px #60606050;

        .tp {
          padding: 5px 1em 5px 1em;
          background-color: $primary;
          color: white;
          display: grid;
          grid-template-columns: 1fr auto;
          border-radius: 5px 5px 0 0;

          h3 {
            padding: 10px;
            font-size: small;
            font-weight: 560;
          }

          h4 {
            font-weight: 400;
            font-size: small;
            display: flex;
            align-items: center;
          }

          .fa {
            cursor: pointer;
            margin-right: 10px;
          }

          .fa:hover {
            color: $accent;
          }
        }

        .bdy {
          padding: 1em;

          .bi_create {
            position: fixed;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background-color: rgba($color: #000000, $alpha: 0.2);
            display: flex;
            align-items: center;
            padding: 1em;

            .wrap {
              max-width: 50vw;
              width: 100%;
              padding: 1em;
              background-color: white;
              margin: auto;
              border-radius: 5px;
              position: relative;
              min-height: 30vh;

              .loading {
                position: fixed !important;
              }

              .div3a {
                display: grid;
                grid-template-columns: 1fr auto auto;
                gap: 1em;
              }

              .check {
                display: grid;
                grid-template-columns: 1fr auto auto 24px;
                gap: 10px;
                padding: 5px;
                border: 1px solid #60606010;
                box-shadow: 1px 1px 5px #60606030;
                border-radius: 5px;
                cursor: pointer;

                .fa-close {
                  display: flex;
                  align-items: center;
                  margin: auto;
                  cursor: pointer;
                  color: orange;
                }
              }

              .check:hover {
                box-shadow: 2px 2px 10px #60606050;
              }

              .fa-times {
                position: absolute;
                top: 10px;
                right: 10px;
                cursor: pointer;
                color: red;
              }

              input {
                background-color: $bg;
                margin-top: 10px !important;
                width: 100% !important;
              }

              hr {
                color: #60606030;
                margin: 10px 0 10px 0;
              }

              p {
                font-size: small;
              }

              h4 {
                color: $primary;
                font-weight: 520;
              }
            }
          }

          .actions {
            display: grid;
            grid-template-columns: auto auto;
            gap: 10px;
            width: fit-content;
            margin-top: 1em;

            .fa {
              height: 24px;
              width: 24px;
              background-color: $primary;
              border-radius: 5px;
              box-shadow: 1px 1px 5px #60606030;
              cursor: pointer;
              display: block;
              color: white;
              padding: 5px;
            }

            .fa:hover {
              background-color: $primarylight;
            }
          }

          .section {
            border: 1px solid #60606030;
            border-radius: 5px;
            padding: 10px;
            margin-bottom: 1em;

            .soitem {
              border: 1px solid #60606030;
              border-radius: 5px;
              padding: 10px;
              margin-bottom: 10px;
            }

            .tphead {
              display: grid;
              grid-template-columns: 1fr 50px 0.3fr 0.3fr 24px;
              background-color: #fdf0f0;
              border-radius: 5px;

              h3 {
                padding: 5px;
                font-size: small;
                font-weight: 520;
                text-align: left;
                width: 100%;
              }

              :nth-child(2) {
                text-align: center;
              }

              :nth-child(3),
              :nth-child(4) {
                text-align: right;
              }
            }

            .tprow {
              display: grid;
              grid-template-columns: 1fr 50px 0.3fr 0.3fr 24px;
              background-color: white;
              border-radius: 5px;
              margin: 4px 0 4px 0;
              border: 1px solid #60606010;
              box-shadow: 1px 1px 5px #60606030;

              h2 {
                padding: 5px;
                font-size: small;
                font-weight: 400;
              }

              :nth-child(2) {
                text-align: center;
              }

              :nth-child(3),
              :nth-child(4) {
                text-align: right;
              }

              .fa-times {
                height: 16px;
                width: 16px;
                align-items: center;
                color: red;
                display: block;
                margin: auto;
                cursor: pointer;
              }

              .fa-times:hover {
                color: orange;
              }
            }

            .item {
              border-bottom: 1px solid #60606010;
              border: 1px solid #60606010;
              padding: 10px;
              margin-bottom: 5px;
              cursor: pointer;

              h2 {
                font-size: small;
                margin-bottom: 2px;
                font-weight: 500;
              }

              h5 {
                font-size: small;
                margin-bottom: 2px;
                color: gray !important;
                font-weight: 200;
              }

              div {
                display: grid;
                grid-template-columns: 1fr auto;
              }

              p {
                font-size: small !important;
              }

              .fa-times {
                margin-left: auto;
                color: $accent;
                display: block;
              }
            }

            .so_list:hover {
              border: 1px solid #60606030;
              background-color: $bg;
            }

            .so_list {
              border-bottom: 1px solid #60606010;
              border: 1px solid #60606010;
              padding: 10px;
              margin-bottom: 5px;
              cursor: pointer;

              h2 {
                font-size: small;
                margin-bottom: 2px;
                font-weight: 500;
              }

              h5 {
                font-size: small;
                margin-bottom: 2px;
                color: gray !important;
                font-weight: 200;
              }
            }

            .search {
              position: relative;

              .search_list {
                position: absolute;
                left: 0;
                right: 0;
                top: 74px;
                padding: 10px;
                border: 1px solid #60606030;
                border-radius: 5px;
                box-shadow: 1px 1px 5px #60606030;
                z-index: 9999;
                background-color: white;

                p {
                  font-size: small;
                  padding: 5px;
                  cursor: pointer;
                  margin-bottom: 3px;
                }

                p:hover {
                  color: $secondary;
                }
              }
            }

            h4 {
              font-size: medium;
              margin-bottom: 10px;
              font-weight: 560;
            }

            hr {
              color: #60606010;
            }

            p {
              font-size: medium;
              margin: 0px 0 5px 0;

              b {
                color: #74465c;
                cursor: pointer;
              }
            }

            .input {
              margin: 10px 0 0 0;

              .pwd {
                position: relative;
                margin: 0px 0 10px 0;

                .fa-eye {
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  right: 5px;
                  color: $secondary;
                  cursor: pointer;
                  display: flex;
                  align-items: center;
                }
              }

              label {
                display: block;
                margin: 10px 0 10px 0;
                font-weight: 400;
                font-size: small;
              }

              textarea {
                margin: 0px 0 10px 0;
                padding: 10px;
                width: 100%;
                background-color: #60606030;
                border-top: none;
                border-right: none;
                border-left: none;
                outline: none;
                border-bottom: 2px solid #60606070;
              }

              textarea:focus {
                border-bottom: 2px solid $primary;
              }

              input {
                padding: 10px;
                width: 100%;
                background-color: #60606030;
                border-top: none;
                border-right: none;
                border-left: none;
                outline: none;
                border-bottom: 2px solid #60606070;
              }

              input:focus {
                border-bottom: 2px solid $primary;
              }
            }

            .select {
              margin: 10px 0 0 0;

              label {
                display: block;
                margin: 10px 0 10px 0;
                font-weight: 400;
                font-size: small;
              }

              select {
                margin: 0px 0 10px 0;
                padding: 10px;
                width: 100%;
                background-color: #60606030;
                border-top: none;
                border-right: none;
                border-left: none;
                outline: none;
                border-bottom: 2px solid #60606070;
              }

              input:focus {
                border-bottom: 2px solid $primary;
              }
            }
          }

          h3 {
            font-size: small;
            font-weight: 400;
            margin: 5px 0 5px auto;
            width: fit-content;

            b {
              font-weight: 520;
            }
          }

          h6 {
            font-size: small;
            font-weight: 400;
            color: $accent;
            height: 16px;
            line-height: 16px;
            text-align: center;
          }

          button {
            display: block;
            margin: 1em auto 0 auto;
            padding: 10px 2em 10px 2em;
            border-radius: 5px;
            width: fit-content;
            background-color: $primary;
            color: white;
            border-top: none;
            border-right: none;
            border-left: none;
            box-shadow: 1px 1px 5px #60606030;
            cursor: pointer;
          }

          button:hover {
            background-color: rgba($color: $primary, $alpha: 0.8);
          }
        }
      }
    }

    .editpopup {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      background-color: rgba($color: black, $alpha: 0.1);
      z-index: 9999;

      .card {
        width: fit-content;
        min-width: 50%;
        margin: auto;
        background-color: white;
        box-shadow: 1px 1px 5px #60606030;
        border-radius: 5px;
        padding: 1em;
        position: relative;

        .fa-times {
          position: absolute;
          right: 10px;
          top: 10px;
          padding: 10px;
          cursor: pointer;
          color: $accent;
        }

        .fa-times:hover {
          color: $secondary;
        }

        h3 {
          font-weight: 520;
          text-align: center;
          margin-bottom: 5px;
        }

        hr {
          color: #60606030;
        }

        .select {
          margin: 10px 0 10px 0;

          label {
            display: block;
            margin: 10px 0 10px 0;
            font-weight: 400;
          }

          select {
            margin: 0px 0 10px 0;
            padding: 10px;
            width: 100%;
            background-color: #60606030;
            border-top: none;
            border-right: none;
            border-left: none;
            outline: none;
            border-bottom: 2px solid #60606070;
          }

          input:focus {
            border-bottom: 2px solid $primary;
          }
        }

        button {
          padding: 10px;
          background-color: $primary;
          color: white;
          cursor: pointer;
          border: none;
          border-radius: 5px;
          margin: auto;
          display: block;
        }

        button:hover {
          background-color: $secondary;
        }
      }
    }
  }

  .payments {
    margin-top: 1em;
    background-color: white;
    border-radius: 5px;
    padding: 1em;

    .pitem {
      display: grid;
      grid-template-columns: 1fr 0.5fr auto;
      gap: 10px;
      padding: 10px;
      background-color: white;
      border: 1px solid $bg;
      border-radius: 5px;
      box-shadow: 1px 1px 5px #60606010;
    }

    .utp {
      display: grid;
      grid-template-columns: 1fr auto auto;
      gap: 1em;

      h3 {
        color: $secondary;
        font-size: medium;
        font-weight: 520;
        display: flex;
        align-items: center;
      }

      p {
        font-size: small;
        color: white;
        background-color: $secondary;
        border-radius: 5px;
        border: 1px solid $secondary;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 5px 10px;

        .fa-add {
          margin-right: 5px;
        }
      }

      p:hover {
        color: $secondary;
        background-color: white;
      }

      .search {
        position: relative;

        .fa-search {
          position: absolute;
          top: 0;
          right: 5px;
          bottom: 0;
          height: 100%;
          display: flex;
          align-items: center;
          color: #60606050;
        }

        input {
          width: 100%;
          padding: 10px;
          outline: none;
          border: 1px solid #60606050;
          font-size: small;
          border-radius: 5px;
        }
      }
    }
  }

  .createinv {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999;

    .wrap {
      background-color: white;
      padding: 20px 10px;
      max-width: 40vw;
      width: 100%;
      border-radius: 10px;
      box-shadow: 1px 1px 10px #60606030;
      position: relative;

      .fa-times {
        position: absolute;
        top: 10px;
        right: 10px;
        color: $accent;
        cursor: pointer;
        transition: color 0.5s ease-in-out;
      }

      .fa-times:hover {
        color: red;
      }

      .input {
        margin: 10px 0 10px 0;

        h4 {
          display: block;
          margin: 0px 0 10px 0;
          font-weight: 400;
        }

        textarea {
          margin: 0px 0 10px 0;
          padding: 10px;
          width: 100%;
          background-color: $bg;
          border-top: none;
          border-right: none;
          border-left: none;
          outline: none;
          border-bottom: 3px solid rgba($primary, 0.5);
        }

        textarea:focus {
          border-bottom: 2px solid $primary;
        }

        input {
          margin: 0px 0 10px 0;
          padding: 10px;
          width: 100%;
          background-color: $bg;
          border-top: none;
          border-right: none;
          border-left: none;
          outline: none;
          border-bottom: 3px solid rgba($primary, 0.5);
        }

        input:focus {
          border-bottom: 2px solid $primary;
        }
      }

      h1 {
        font-size: medium;
        color: $primary;
      }

      p {
        font-size: small;
        margin: 8px 0 8px 0;
      }

      button {
        background-color: $primary;
        color: white;
        padding: 5px 2em;
        cursor: pointer;
        display: block;
        margin: auto;
        border: none;
        border-radius: 5px;
        margin-bottom: 10px;
      }

      button:hover {
        background-color: $secondary;
      }

      h4 {
        color: red;
        cursor: pointer;
        display: block;
        margin: auto;
        width: fit-content;
        font-weight: 400;
        font-size: small;
      }

      h4:hover {
        color: $primary;
      }
    }
  }

  @include maxwidth(tablet) {
    .top {
      grid-template-columns: 1fr !important;

      .topcharts {
        .topsummary {
          grid-template-columns: repeat(2, 1fr);
          gap: 10px;
        }
      }

      .topright {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    .mid {
      .midtop-cards {
        grid-template-columns: 1fr;
      }

      .chart {
        height: fit-content;
        min-height: fit-content;
      }
    }

    .pies {
      grid-template-columns: 1fr !important;
    }

    .bottom {
      grid-template-columns: 1fr !important;
    }

    .middle {
      grid-template-columns: 1fr !important;
    }
  }

  @include maxwidth(mobile) {
    .top {
      grid-template-columns: 1fr !important;

      .topcharts {
        .topsummary {
          grid-template-columns: repeat(2, 1fr);
          gap: 10px;
        }
      }

      .topright {
        grid-template-columns: 1fr !important;
      }
    }

    .mid {
      .midtop-cards {
        grid-template-columns: 1fr;
      }

      .chart {
        height: fit-content;
        min-height: fit-content;
      }
    }

    .pies {
      grid-template-columns: 1fr !important;
    }

    .bottom {
      grid-template-columns: 1fr !important;
    }

    .middle {
      grid-template-columns: 1fr !important;
    }
  }
}
