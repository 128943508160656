@import "./mediaquery";
@import "./variables";

.employees {
  height: fit-content !important;

  .leading {
    padding: 1em 0;
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 1em;

    h1 {
      color: $primary;
      font-size: x-large;
    }
    button {
      padding: 8px 1em;
      background-color: $primary;
      color: white;
      border: 1px solid $primary;
      cursor: pointer;
      border-radius: 5px;
      box-shadow: 1px 1px 5px #60606010;
      transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out;
    }
    button:hover {
      color: $primary;
      background-color: transparent;
    }
  }

  .container {
    background-color: white;
    padding: 1em;
    border-radius: 5px;
    box-shadow: 1px 1px 5px #60606030;
    min-height: 70vh;
    height: 100%;

    .intro {
      margin: 1em 0;

      h4 {
        color: #686868;
        font-size: medium;
        font-weight: 520;
        margin: 10px 0;
      }

      .div1auto {
        display: grid;
        grid-template-columns: 1fr auto auto;
        gap: 1em;

        select {
          padding: 8px;
          border: 1px solid #60606030;
          border-radius: 5px;
          outline: none;
          height: fit-content;
          background-color: transparent;
          margin-top: auto;
        }

        .search {
          position: relative;
          height: fit-content;

          input {
            padding: 8px 8px 8px 2.5em;
            border: 1px solid #60606030;
            border-radius: 5px;
            outline: none;
          }

          .ic {
            position: absolute;
            left: 10px;
            top: 0;
            bottom: 0;
            height: 100%;
            line-height: 100%;
            color: #606060;
            display: grid;
            place-content: center;
          }
        }

        button {
          margin-top: 10px;
          padding: 8px 1em;
          background-color: $secondary;
          color: white;
          border: 1px solid $secondary;
          cursor: pointer;
          border-radius: 5px;
          box-shadow: 1px 1px 5px #60606010;
          transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out;
        }
        button:hover {
          color: $secondary;
          background-color: transparent;
        }
      }
    }

    .list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1em;

      .row {
        background-color: white;
        border-radius: 8px;
        box-shadow: 1px 1px 5px #60606010;
        border: 1px solid #60606010;
        transition: background-color 0.5s ease-in-out;
        padding: 1em;
        position: relative;

        .div1auto {
          display: grid;
          grid-template-columns: auto 1fr auto;
          gap: 1em;

          .no {
            height: 32px;
            width: 32px;
            border-radius: 50%;
            background-color: antiquewhite;
            border: 1px solid #60606010;
            font-size: large;
            font-weight: 560;
            color: $primary;
            display: grid;
            place-content: center;
          }

          h3 {
            font-size: medium;
            font-weight: 560;
          }

          p {
            font-size: small;
            color: #686868;
            text-align: left;
            width: 100%;
            padding: 0;
          }

          .gender {
            display: block;
            margin: auto;
          }
        }

        hr {
          margin: 8px 0;
          border-color: rgb(236, 236, 236);
          background-color: rgb(236, 236, 236);
        }

        .designation {
          border-radius: 24px;
          padding: 4px 1em;
          background-color: #60606010;
          width: fit-content;
          color: $primary;
          font-size: small;
        }

        p {
          padding: 4px 8px;
          color: #686868;
          font-weight: 500;
          font-size: smaller;
          margin: auto;
          display: block;
          width: 100%;
        }
        b {
          color: $secondary;
        }
        .edit {
          position: absolute;
          right: 10px;
          bottom: 10px;
          color: $secondary;
          display: block;
          margin: auto;
          font-size: medium;
          cursor: pointer;
          transition: color 0.5s ease-in-out;
        }

        .delete {
          position: absolute;
          right: 2.5em;
          bottom: 10px;
          color: $secondary;
          display: block;
          margin: auto;
          font-size: medium;
          cursor: pointer;
          transition: color 0.5s ease-in-out;
        }

        .delete:hover,
        .edit:hover {
          color: $primarylight;
        }
      }

      .row:hover {
        background-color: #60606010;
      }
    }
  }

  .popup {
    padding: 2em;

    .wrap {
      background-color: white;
      max-width: 60vw;
      width: 100%;
      margin: auto;
      border-radius: 8px;
      overflow-y: auto;
      height: 100%;

      .bar {
        background-color: $primary;
        display: grid;
        grid-template-columns: 1fr auto;
        padding: 10px;
        h4 {
          color: white;
          font-weight: 500;
        }
      }
      form {
        padding: 1em;

        h6 {
          color: $accent;
          text-align: center;
          margin: 10px 0;
          font-weight: 500;
          font-size: medium;
        }

        h4 {
          font-weight: 500;
          margin-bottom: 8px;
        }

        .checkbox {
          display: grid;
          grid-template-columns: auto 1fr;
          gap: 10px;
          margin: 8px 0;

          input {
            border-radius: 5px;
          }
        }

        button {
          padding: 8px 1em;
          background-color: $primary;
          color: white;
          border: 1px solid $primary;
          cursor: pointer;
          border-radius: 5px;
          display: block;
          margin: auto;
          min-width: 200px;
          box-shadow: 1px 1px 5px #60606010;
          transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out;
        }
        button:hover {
          color: $primary;
          background-color: transparent;
        }
      }
    }

    .mrap {
      background-color: white;
      max-width: 40vw;
      width: fit-content;
      margin: auto;
      border-radius: 8px;

      h6 {
        color: $accent;
        text-align: center;
        margin: 10px 0;
        font-weight: 500;
        font-size: medium;
      }

      button {
        padding: 8px 1em;
        background-color: $primary;
        color: white;
        border: 1px solid $primary;
        cursor: pointer;
        border-radius: 5px;
        display: block;
        margin: auto;
        min-width: 200px;
        box-shadow: 1px 1px 5px #60606010;
        transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out;
      }
      button:hover {
        color: $primary;
        background-color: transparent;
      }

      .bar {
        background-color: $primary;
        display: grid;
        grid-template-columns: 1fr auto;
        padding: 10px;
        h4 {
          color: white;
          font-weight: 500;
        }
      }
      .section {
        padding: 1em;
        .div2equal {
          margin: 0 !important;
          gap: 0 !important;
          grid-template-columns: 2fr 1fr !important;

          h3 {
            background-color: antiquewhite;
            color: $primary;
            font-size: smaller;
            font-weight: 520;
            border: 1px solid #60606010;
            height: 100%;
            padding: 4px;
          }

          h4 {
            background-color: rgb(252, 246, 239);
            font-size: smaller;
            font-weight: 500;
            border: 1px solid #60606010;
            height: 100%;
            padding: 4px;
          }

          p {
            background-color: white;
            font-size: smaller;
            font-weight: 500;
            border: 1px solid #60606010;
            height: 100%;
            padding: 4px;
            width: 100%;
            text-align: right;
            display: grid;
            align-items: center;
            // place-content: center;
          }

          input {
            background-color: white;
            font-weight: 500;
            border: 1px solid #60606010;
            padding: 4px;
            height: 100%;
            width: 100%;
            text-align: right;
            display: grid;
            place-content: center;
            outline: none;
          }
        }
      }
    }
  }
}
